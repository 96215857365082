import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'
import { type TimeModel } from '@vuepic/vue-datepicker'
import { format, intervalToDuration } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

const { getLocale } = useLocale()

const TIME_ZONE_DEFAULT = 'Europe/Amsterdam'
const TIME_FORMAT_HOURS_MINUTES = 'HH:mm'
const TIME_FORMAT_HOURS_MINUTES_SECONDS = 'HH:mm:ss'
export const DATE_FORMAT = 'dd-MM-yyyy'

export function getDateToday() {
    const date = new Date()
    return date.toLocaleDateString('nl-NL')
}

export function getDateFromDateTime(datetime: Date | string, style: 'full' | 'long' | 'medium' | 'short' | undefined = 'long') {
    const date = new Date(datetime)
    return new Intl.DateTimeFormat('nl-NL', { dateStyle: style }).format(date)
}

export function getTimeFromDateTime(datetime: string | Date) {
    const date = new Date(datetime)
    return new Intl.DateTimeFormat('nl-NL', { timeStyle: 'short' }).format(date)
}

export function getZonedDateTime(date: string, seconds = true) {
    let timeFormat = seconds ? TIME_FORMAT_HOURS_MINUTES_SECONDS : TIME_FORMAT_HOURS_MINUTES
    return formatInTimeZone(new Date(date), TIME_ZONE_DEFAULT, DATE_FORMAT + ' ' + timeFormat)
}

export function getAppointmentDateFromDateTime(datetime: string | Date, dayLength: 'long' | 'short' | undefined = 'long') {
    const date = new Date(datetime)
    const style = dayLength === 'long' ? 'EEEE d MMMM' : 'EEEEEE d MMMM'

    return ucfirst(format(date, style, { locale: getLocale() }))
}

export function getZonedDate(date: Date | string | null) {
    if (date === null) return null

    return formatInTimeZone(new Date(date), TIME_ZONE_DEFAULT, DATE_FORMAT)
}

export function getZonedTime(date: string, seconds = true) {
    let timeFormat = seconds ? TIME_FORMAT_HOURS_MINUTES_SECONDS : TIME_FORMAT_HOURS_MINUTES
    return formatInTimeZone(new Date(date), TIME_ZONE_DEFAULT, timeFormat)
}

export function timeToDuration(seconds: number) {
    const duration = {
        minutes: 0,
        seconds: 0,
        ...intervalToDuration({ start: 0, end: seconds * 1000 }),
    }

    return `${duration.minutes.toString().padStart(2, '0')}:${duration.seconds.toString().padStart(2, '0')}`
}

// From: https://stackoverflow.com/questions/9640266/convert-hhmmss-string-to-seconds-only-in-javascript
export function getSecondsFromHmsString(hms: string) {
    var p = hms.split(':'),
        s = 0,
        m = 1

    let value

    while ((value = p.pop())) {
        s += m * parseInt(value, 10)
        m *= 60
    }

    return s
}

export function getTimeFromTimeObject(time: TimeModel, stripSeconds = false) {
    let formattedTime = addLeadingZero(+time.hours) + ':' + addLeadingZero(+time.minutes)

    if (!stripSeconds) {
        formattedTime += ':' + addLeadingZero(+(time.seconds || 0))
    }

    return formattedTime
}

export function timeStringToModel(timeStr?: string | null) {
    if (!timeStr) return null

    const timeParts = timeStr.split(':')

    return {
        hours: +timeParts[0],
        minutes: +timeParts[1],
        seconds: +(timeParts[2] || 0),
    } satisfies TimeModel
}

export function addLeadingZero(number: number) {
    if (number.toString().length === 1) {
        return '0' + number
    }

    return number
}

export function normalizeTimeString(timeStr: string) {
    if (timeStr.length === 5) {
        return `${timeStr}:00`
    }

    return timeStr
}
