<script setup lang="ts">
import type { PaginationLink } from '@app/types/shared'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed } from 'vue'

let props = defineProps<{
    links: PaginationLink[]
    only?: string[]
}>()

const linksWithUrl = computed(() => props.links.filter(({ url }) => url !== null))

const prevLink = computed(() => linksWithUrl.value.find((link) => link.label === trans('pagination.previous')))

const pages = computed(() => linksWithUrl.value.filter(({ label }) => ![trans('pagination.previous'), trans('pagination.next')].includes(label)))

const nextLink = computed(() => linksWithUrl.value.find((link) => link.label === trans('pagination.next')))
</script>
<template>
    <nav v-if="links.length > 3" class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
        <div class="-mt-px flex w-0 flex-1">
            <Link
                v-if="prevLink"
                :href="prevLink.url"
                prefetch
                :only="only"
                class="inline-flex items-center border-t-2 border-transparent p-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                <ArrowLongLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ $t('pagination.previous') }}
            </Link>
        </div>
        <div v-for="link in pages" class="hidden md:-mt-px md:flex">
            <template v-if="link.label !== $t('pagination.previous') && link.label !== $t('pagination.next')">
                <Link
                    v-if="!link.active"
                    :href="link.url"
                    prefetch
                    :only="only"
                    class="inline-flex items-center border-t-2 border-transparent p-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    {{ link.label }}
                </Link>
                <Link
                    v-else
                    :href="link.url"
                    prefetch
                    :only="only"
                    class="border-primary-500 text-primary-600 inline-flex items-center border-t-2 p-4 text-sm font-medium"
                >
                    {{ link.label }}
                </Link>
            </template>
        </div>
        <div class="-mt-px flex w-0 flex-1 justify-end">
            <Link
                v-if="nextLink"
                prefetch
                :href="nextLink.url"
                :only="only"
                class="inline-flex items-center border-t-2 border-transparent p-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                {{ $t('pagination.next') }}
                <ArrowLongRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Link>
        </div>
    </nav>
</template>
