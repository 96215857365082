<script setup lang="ts">
import Alert from '@app/components/ui/alert/Alert.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import ModalTitle from '@app/components/ui/calendar/modal/ModalTitle.vue'
import Label from '@app/components/ui/dropdown/Label.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import { useCreateShiftAvailabilityForm } from '@app/composables/shifts/availabilities/useCreateShiftAvailabilityForm'
import { useLocale } from '@app/composables/useLocale'
import type { PageProps } from '@app/types/inertia'
import type { ShiftTemplate } from '@app/types/shifts'
import { getDateFromDateTime } from '@app/utils/date'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { sortObjectByKey } from '@app/utils/object'
import { OpeningHours, type OpeningHoursState } from '@app/utils/openingHours'
import { ucfirst } from '@app/utils/transform'
import { ArrowsPointingInIcon, CalendarIcon, ClockIcon, MapIcon, PaperClipIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'
import { eachDayOfInterval, endOfWeek, format, startOfWeek, type DateArg } from 'date-fns'
import { trans } from 'laravel-vue-i18n'
import isEmpty from 'lodash/isEmpty'
import { computed } from 'vue'

const { date, userShiftTemplates } = defineProps<{
    date: Date
    userShiftTemplates: ShiftTemplate[]
}>()

const user = computed(() => usePage<PageProps>().props.user)

const { form, clientProjects, selectedWorkTypeSlug, selectableLocationTypes, selectableBranches, selectableShiftHours, submit } =
    useCreateShiftAvailabilityForm(userShiftTemplates, user.value)

const openingHoursByWorkType = computed(() => usePage<PageProps>().props.shiftOpeningHours as Record<string, OpeningHoursState>)

const openingHours = computed(() => {
    if (selectedWorkTypeSlug.value && openingHoursByWorkType.value[selectedWorkTypeSlug.value]) {
        return new OpeningHours(openingHoursByWorkType.value[selectedWorkTypeSlug.value])
    }

    return null
})

const availabilityIsEnabled = computed(() => userShiftTemplates.length > 0)

const selectedWeek = computed(() => format(date, 'w'))
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(date, { weekStartsOn: 1 }),
        end: endOfWeek(date, { weekStartsOn: 1 }),
    })
)

const selectableDays = computed(() => {
    if (!openingHours.value) {
        return days.value
    }

    return days.value.filter((day) => !openingHours.value?.isClosedAt(day))
})

function getShiftHoursForDay(day: DateArg<Date>) {
    return selectableShiftHours.value.filter((item) => openingHours.value?.isTimeWithinOpeningHours(day, item.start_time, item.end_time) ?? true)
}

function toggleSelectedDay(day: Date) {
    const key = day.toDateString()

    if (form.shift_availabilities[key]) {
        delete form.shift_availabilities[key]
    } else {
        const defaultShiftHours = selectableShiftHours.value.find((item) => item.is_default)

        form.shift_availabilities[key] = {
            date: key,
            shift_hour_id: defaultShiftHours?.id ?? null,
        }

        form.shift_availabilities = sortObjectByKey(form.shift_availabilities, 'date')
    }
}

const { getLocale } = useLocale()

const emit = defineEmits(['close'])
</script>

<template>
    <div class="w-[23rem] p-6 md:min-w-[32rem]">
        <div class="flex w-full flex-col items-start gap-3">
            <div class="flex gap-2">
                <div class="bg-primary-100 flex h-10 w-10 shrink-0 items-center justify-center rounded-full">
                    <CalendarIcon class="text-primary-600 h-6 w-6" aria-hidden="true" />
                </div>
                <ModalTitle :label="trans('calendar.availability.add')" />
            </div>
            <div class="flex w-full flex-col gap-6 pl-4 md:pl-12">
                <p class="text-base leading-6 font-medium text-zinc-900">
                    {{ format(days[0], 'dd') }} - {{ getDateFromDateTime(days[days.length - 1]) }} ({{ $t('calendar.week') }} {{ selectedWeek }})
                </p>
                <Alert v-if="!availabilityIsEnabled" type="warning">
                    {{ $t('shifts.create_availability.missing_working_range') }}
                </Alert>

                <div v-else class="flex flex-col gap-6 pb-6 text-base leading-6 font-medium text-zinc-700">
                    <div class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <PaperClipIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.client_project_id"
                                :placeholder="translatedSelectPlaceholder('attributes.client_project')"
                                :disabled="clientProjects.length === 0"
                                autoselect
                            >
                                <SelectOption
                                    v-for="clientProject in clientProjects"
                                    :value="clientProject.id"
                                    :label="clientProject.name"
                                    :key="clientProject.id"
                                />
                            </Select>
                        </div>
                    </div>
                    <div v-if="selectedWorkTypeSlug !== 'promotions'" v-show="selectableLocationTypes.length > 1" class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <ArrowsPointingInIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />

                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.location_type_id"
                                :placeholder="translatedSelectPlaceholder('attributes.location_type')"
                                autoselect
                            >
                                <SelectOption
                                    v-for="locationType in selectableLocationTypes"
                                    :value="locationType.id"
                                    :label="locationType.name"
                                    :key="locationType.id"
                                />
                            </Select>
                        </div>
                    </div>
                    <div v-if="selectableBranches.length > 0" class="flex flex-col gap-2">
                        <div class="flex items-center space-x-4">
                            <MapIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                :border="false"
                                v-model="form.branch_id"
                                :placeholder="translatedSelectPlaceholder('attributes.branch')"
                                autoselect
                            >
                                <SelectOption v-for="branch in selectableBranches" :value="branch.id" :label="branch.name" :key="branch.id" />
                            </Select>
                        </div>
                    </div>
                </div>
                <div v-if="selectableShiftHours.length > 0" class="flex flex-col gap-4 border-t border-zinc-200 pb-6">
                    <Label :label="$t('form.select') + $t('recruitment.availabilities.days')" />
                    <div class="flex gap-3 text-xs leading-6 text-zinc-500">
                        <button
                            v-for="day in selectableDays"
                            @click="toggleSelectedDay(day)"
                            :class="[
                                form.shift_availabilities[day.toDateString()] ? 'bg-primary-500 text-white' : 'bg-zinc-100 text-zinc-500',
                                'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-xs leading-4 font-medium tracking-wide uppercase',
                            ]"
                        >
                            {{
                                format(day, 'EEEEEE', {
                                    locale: getLocale(),
                                })
                            }}
                        </button>
                    </div>
                    <div v-for="(availability, index) in form.shift_availabilities">
                        <div class="text-sm leading-4 text-zinc-500">
                            {{ ucfirst(format(availability.date, 'EEEE', { locale: getLocale() })) }}
                            {{ format(availability.date, 'dd-MM-yyyy', { locale: getLocale() }) }}
                        </div>
                        <div class="mt-2 flex items-center space-x-4">
                            <ClockIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                            <Select
                                class="w-2/3"
                                v-model="form.shift_availabilities[index].shift_hour_id"
                                :placeholder="translatedSelectPlaceholder('attributes.time')"
                            >
                                <SelectOption
                                    v-for="shiftHours in getShiftHoursForDay(availability.date)"
                                    :value="shiftHours.id"
                                    :label="`${shiftHours.start_time} - ${shiftHours.end_time}`"
                                    :key="shiftHours.id"
                                />
                            </Select>
                        </div>
                        <FieldError
                            v-if="form.errors.shift_availabilities && index in form.errors.shift_availabilities"
                            :message="form.errors.shift_availabilities[index]"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="availabilityIsEnabled" class="flex flex-row-reverse gap-4 px-6 py-3">
        <SaveButton :disabled="isEmpty(form.shift_availabilities) || form.processing" @click="submit(() => $emit('close'))">{{
            $t('calendar.availability.add')
        }}</SaveButton>
        <CancelButton @click="emit('close')" />
    </div>
</template>
