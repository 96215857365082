<script setup lang="ts">
import { computed, inject, type ComputedRef } from 'vue'
import Weekdays from '../Weekdays.vue'

const days = inject('calendar:days') as ComputedRef<Date[]>

const columns = computed(() => days.value.length)
</script>

<template>
    <div
        ref="container"
        class="scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 isolate flex flex-auto flex-col overflow-y-auto bg-white lg:overflow-x-hidden"
    >
        <div class="flex w-[200%] flex-none flex-col lg:w-full">
            <div class="sticky top-0 z-50 flex-none shadow-xs ring-1 ring-black/5">
                <div
                    class="grid divide-x divide-zinc-100 border-b border-zinc-100 bg-white text-xs leading-4 text-zinc-500"
                    :style="{ 'grid-template-columns': `repeat(${columns}, minmax(0, 1fr))` }"
                >
                    <div class="col-end-1 w-14" />
                    <Weekdays :days />
                </div>
            </div>

            <div class="flex">
                <slot />
            </div>
        </div>
    </div>
</template>
