<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Input from '@app/components/ui/form/Input.vue'
import type { OngoingShift } from '@app/types/shifts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const { shift } = defineProps<{ shift: OngoingShift }>()

const emit = defineEmits(['close'])

const form = useForm({
    break_extra_minutes: 0,
    note: '',
    desk_sanitized: false,
    truthful_task_completion: false,
})

function endShift() {
    form.post(route('shifts.member.end', shift.shift_member_id), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
    })
}
</script>
<template>
    <Form :form="form" @submit="endShift" class="max-w-xl p-8">
        <FormSection :title="$t('buttons.end_shift')">
            <FormField prop="break_extra_minutes" :label="$t('shifts.break_extra_minutes')" class="col-span-12">
                <Input id="contract_job_title" v-model="form.break_extra_minutes" autocomplete="off" autofocus />
            </FormField>
            <FormField prop="note" :label="$t('shifts.note')" class="col-span-12">
                <FormTextArea id="note" v-model="form.note" />
            </FormField>
            <FormField prop="desk_sanitized" class="col-span-12">
                <Checkbox id="desk_sanitized" v-model="form.desk_sanitized">
                    {{ $t('shifts.sanitize_desk') }}
                </Checkbox>
            </FormField>
            <FormField prop="truthful_task_completion" class="col-span-12">
                <Checkbox id="truthful_task_completion" v-model="form.truthful_task_completion">
                    {{ $t('shifts.truthful_task_completion') }}
                </Checkbox>
            </FormField>
        </FormSection>
        <FormActions>
            <SaveButton :disabled="form.processing || !form.desk_sanitized || !form.truthful_task_completion" />
        </FormActions>
    </Form>
</template>
