<script setup>
import { applyFilter } from '@app/utils/filter'
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'
import { ref, watch } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    show: {
        type: Boolean,
        default: true,
    },
    text: {
        type: String,
        required: true,
    },
    arrayNumber: {
        type: Number,
        required: true,
    },
    only: String,
    sort: String,
})

const params = route().params

const getClass = () => {
    return props.arrayNumber === 0 ? 'py-3.5 pl-4 sm:pl-6 pr-3' : 'px-3 py-3.5'
}

const sortDirection = ref(params['sort_' + props.sort] ? params['sort_' + props.sort] : null)

const toggleSort = () => {
    if (props.sort) {
        sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc'
    }
}

watch(sortDirection, (newValue) => {
    let propertyName = 'sort_' + props.sort

    const sorts = Object.entries(route().params).reduce(
        (appliedSorts, [key, _]) => {
            if (key.startsWith('sort_') && key !== propertyName) {
                appliedSorts[key] = null
            }

            return appliedSorts
        },
        {
            [propertyName]: newValue,
        }
    )

    applyFilter(sorts, { only: [props.only] })
})
</script>

<template>
    <th v-if="show" scope="col" :class="getClass()" class="text-left text-xs font-medium uppercase tracking-wide text-gray-500" @click="toggleSort">
        <div class="group inline-flex items-center">
            {{ text }}
            <span v-if="sort" class="ml-2 flex-none rounded-sm text-gray-400 group-hover:visible group-focus:visible">
                <ChevronDownIcon v-if="sortDirection === 'desc'" class="h-5 w-5" aria-hidden="true" />
                <ChevronUpIcon v-else-if="sortDirection === 'asc'" class="h-5 w-5" aria-hidden="true" />
                <ChevronUpDownIcon v-else="sortDirection === 'asc'" class="h-5 w-5" aria-hidden="true" />
            </span>
        </div>
    </th>
</template>
