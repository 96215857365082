<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'
import { format, isToday } from 'date-fns'
import { type PropType } from 'vue'
import TimeGridHeader from './body/TimeGridHeader.vue'

defineProps({
    days: {
        type: Array as PropType<Date[]>,
        required: true,
    },
    shorthand: {
        type: Boolean,
        default: false,
    },
    sortable: {
        type: Boolean,
        default: false,
    },
    sortedDay: {
        type: Date,
    },
})

const emit = defineEmits(['sort'])
const { getLocale } = useLocale()
</script>

<template>
    <TimeGridHeader v-for="day in days" :active="sortedDay === day" :sortable @sort="$emit('sort', day)">
        <span>{{ shorthand ? ucfirst(format(day, 'EEEEEE', { locale: getLocale() })) : ucfirst(format(day, 'EEEE', { locale: getLocale() })) }}</span>
        <span
            :class="[
                isToday(day) ? 'bg-primary-600 rounded-full text-white' : 'text-zinc-900',
                'flex h-8 w-8 flex-none items-center justify-center font-semibold md:mt-0',
            ]"
            >{{ format(day, 'd') }}</span
        >
    </TimeGridHeader>
</template>
