<script setup lang="ts" generic="T extends boolean | number | string | null">
import { computed } from 'vue'

const model = defineModel<T>()

const { name, value } = defineProps<{ name: string; value: T | null }>()

const id = computed(() => `${name}-${value}`)
</script>

<template>
    <input :id :name class="hidden" type="radio" v-model="model" :value :checked="model === value" />
    <label :for="id"><slot></slot></label>
</template>

<style scoped>
label {
    position: relative;
    margin-bottom: 0;
    line-height: 1.5rem;
    padding-left: 30px;
    cursor: pointer;
}

label::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: var(--color-white);
    border-radius: 50%;
    border: 1px solid var(--color-wnl-purple);
    transition:
        background-color 0.1sease-in-out,
        box-shadow 0.15sease-in-out;
    content: '';
}

input:checked + label::before {
    background-color: var(--color-wnl-purple);
    position: absolute;
    content: '';
    background-image: url(/assets/wnl/checkmark_white.svg);
    background-size: 60%;
    background-position: 50% 60%;
    background-repeat: no-repeat;
}
</style>
