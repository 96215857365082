<script setup lang="ts">
import { useFileInput, type FileInputModel } from '@app/composables/form/useFileInput'
import { CameraIcon, XMarkIcon } from '@heroicons/vue/20/solid'

const model = defineModel<FileInputModel>()

const { filePreview, selectFile } = useFileInput(model)
</script>

<template>
    <div v-if="filePreview" class="relative">
        <button class="absolute -top-4 -right-4 rounded-full bg-zinc-700 p-1 shadow-md transition-colors hover:bg-zinc-800" @click="model = null">
            <XMarkIcon class="size-6 text-zinc-200" />
        </button>
        <img v-if="filePreview" :src="filePreview" class="rounded-md" />
    </div>

    <label v-else class="flex flex-col items-center justify-center gap-2 rounded-md border-2 border-dashed border-zinc-300 p-6">
        <CameraIcon class="size-12 text-zinc-400" />
        <p class="self-center text-center text-sm leading-5 font-medium">
            <span class="text-zinc-400"><slot /></span>
        </p>

        <input type="file" class="hidden" ref="input:file" @change="selectFile" accept="image/*" capture="user" />
    </label>
</template>
