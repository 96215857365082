<script setup lang="ts">
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import axios from 'axios'
import debounce from 'lodash/debounce'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'

type SearchItem = {
    name: string
    url: string
}

type SearchSet = {
    name: string
    items: SearchItem[]
}

const searchMinimumLength: number = 2
const searchText = ref('')
const isSearchNotEmpty = computed(() => searchText.value.length >= searchMinimumLength)
const searchResults = ref<SearchSet[]>([])
const searchResultsVisible = ref(false)
const isTyping = ref(false)

async function performSearch(query: string) {
    if (query.length >= searchMinimumLength) {
        const data = await axios.get(route('api.v1.search', { query }))

        searchResults.value = data.data
    } else {
        searchResults.value = []
    }

    isTyping.value = false
}

const debouncedSearch = debounce(performSearch, 300)

watch(searchText, () => {
    isTyping.value = true
    debouncedSearch(searchText.value)
})

const clearSearch = (): void => {
    searchText.value = ''
}

const showSearchResults = (): void => {
    searchResultsVisible.value = true
}

const hideSearchResults = (): void => {
    searchResultsVisible.value = false
    searchText.value = ''
}

const onInputFocus = (): void => {
    if (searchText.value.length >= searchMinimumLength) {
        searchResultsVisible.value = true
    }
}
</script>

<template>
    <div class="relative flex flex-1 items-center justify-between space-x-6 focus-within:z-20 md:max-w-96">
        <div class="flex flex-1">
            <label for="search-field" class="sr-only">Search all files</label>
            <div class="relative w-full text-zinc-400 focus-within:text-zinc-300">
                <div class="pointer-events-none absolute inset-y-0 left-3 flex items-center">
                    <MagnifyingGlassIcon :class="['h-5 w-5 shrink-0', isSearchNotEmpty ? 'text-zinc-600' : 'text-zinc-400']" aria-hidden="true" />
                </div>
                <input
                    id="search-field"
                    name="search-field"
                    class="focus:outline-primary-600 w-full rounded-md border border-transparent border-zinc-200 bg-zinc-50 py-2 pl-10 pr-10 text-base text-zinc-600 placeholder-zinc-400 focus:border-transparent focus:placeholder-zinc-300 focus:ring-0"
                    :placeholder="$t('search.placeholder')"
                    type="search"
                    v-model="searchText"
                    @input="showSearchResults"
                    @focus="onInputFocus"
                    autocomplete="off"
                />
                <div v-if="isSearchNotEmpty" class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3" :class="{ 'z-50': searchResultsVisible }">
                    <XMarkIcon class="h-5 w-5 text-zinc-600 duration-100 hover:text-zinc-400" @click="clearSearch" />
                </div>
            </div>
            <div
                v-if="searchResultsVisible && !isTyping && isSearchNotEmpty"
                class="shadow-xs absolute top-full z-50 mt-2 w-full min-w-72 rounded-md border-transparent bg-white p-4 sm:min-w-96 sm:max-w-2xl"
            >
                <ul>
                    <li v-for="resultSet in searchResults">
                        <div class="text-primary-500 py-2 text-sm font-semibold leading-5">{{ $t(resultSet.name) }}</div>
                        <ul>
                            <li v-for="item in resultSet.items">
                                <Link
                                    class="block cursor-pointer rounded-md p-2 text-sm font-normal leading-5 text-zinc-600 transition-all duration-200 hover:bg-zinc-100 hover:text-zinc-800"
                                    :href="item.url"
                                    @click="hideSearchResults"
                                >
                                    {{ item.name }}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <div v-if="!isTyping && searchResults.length === 0" class="text-sm font-normal leading-5 text-zinc-500">
                        {{ $t('search.no_results') }}
                    </div>
                </ul>
            </div>
        </div>
    </div>
    <!-- Overlay -->
    <Transition name="fade">
        <div
            class="fixed inset-0 z-10 bg-black/50 transition-all duration-500"
            v-show="isSearchNotEmpty && searchResultsVisible"
            @click="hideSearchResults"
        ></div>
    </Transition>
</template>
