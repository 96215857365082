import type { PlanningShift, ShiftAvailabilityEvent } from '@app/types/shifts'
import { useForm } from '@inertiajs/vue3'
import { isPast } from 'date-fns'
import { inject, provide, ref, type InjectionKey } from 'vue'

const shiftPlanningKey: InjectionKey<ReturnType<typeof createShiftPlanning>> = Symbol()

function createShiftPlanning() {
    const shiftPlanningForm = useForm<{ shifts: PlanningShift[] }>({
        shifts: [],
    })

    const draggingAvailability = ref<ShiftAvailabilityEvent>()

    function isSelected(availability: ShiftAvailabilityEvent) {
        return shiftPlanningForm.shifts.some((shift) => shift.availabilities.some((member) => member.id === availability.id))
    }

    function isDisabled(availability: ShiftAvailabilityEvent) {
        return !!availability.shift_id || isPast(availability.to)
    }

    function reset() {
        shiftPlanningForm.shifts = []
        draggingAvailability.value = undefined
    }

    return {
        shiftPlanningForm,
        draggingAvailability,
        isSelected,
        isDisabled,
        reset,
    }
}

export function provideShiftPlanning() {
    const shiftPlanning = createShiftPlanning()

    provide(shiftPlanningKey, shiftPlanning)

    return shiftPlanning
}

export function useShiftPlanning() {
    const shiftPlanning = inject(shiftPlanningKey)

    if (!shiftPlanning) {
        throw new Error('provideShiftPlanning should be called in parent component')
    }

    return shiftPlanning
}
