import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'

function findStepByName(steps: FormStep[], stepName?: string | null) {
    if (!stepName) return null

    return steps.find(({ slug }) => stepName === slug)
}

export function useFormWizard(steps: FormStep[]) {
    const currentStep = computed(() => steps.find(({ isActive }) => isActive))
    const currentStepIndex = computed(() => steps.findIndex(({ isActive }) => isActive))

    const previousStep = computed(() => findStepByName(steps, currentStep.value?.previousStep))
    const nextStep = computed(() => findStepByName(steps, currentStep.value?.nextStep))

    function navigateToPreviousStep() {
        if (previousStep.value) {
            router.get(previousStep.value.url)
        }
    }

    function navigateToNextStep() {
        if (nextStep.value) {
            router.get(nextStep.value.url)
        }
    }

    return {
        currentStep,
        currentStepIndex,
        previousStep,
        nextStep,
        navigateToPreviousStep,
        navigateToNextStep,
    }
}
