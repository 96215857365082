import { isImage } from '@app/utils/file'
import { computed, onUnmounted, useTemplateRef, type ModelRef } from 'vue'

export type FileInputModel = File | string | null | undefined

export type FileInputProps = {
    limit?: number
    fileTypes?: string[]
    accept?: string
}

export function useFileInput(model: ModelRef<FileInputModel>) {
    const fileInput = useTemplateRef<HTMLInputElement>('input:file')

    const canPreview = computed(() => isImage(model.value))

    const filePreview = computed(() => {
        if (!canPreview.value) {
            return false
        }

        if (model.value instanceof File) {
            return URL.createObjectURL(model.value)
        }

        return model.value
    })

    function selectFile() {
        model.value = fileInput.value?.files?.[0] || null
    }

    onUnmounted(() => {
        if (filePreview.value) {
            URL.revokeObjectURL(filePreview.value)
        }
    })

    return {
        fileInput,
        selectFile,
        filePreview,
    }
}
