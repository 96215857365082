<script setup lang="ts">
import DismissButton from '@app/components/ui/button/DismissButton.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import { useOrderForm } from '@app/composables/orders/useOrderForm'
import { Head } from '@inertiajs/vue3'
import FormSteps from './FormSteps.vue'

const { steps } = defineProps<{ steps: FormStep[]; title: string; description?: string }>()

const { currentStepIndex } = useFormWizard(steps)

const { closeForm } = useOrderForm('wnl', steps)
</script>

<template>
    <Head :title="`Stap ${currentStepIndex + 1} - Word lid van WNL!`" />
    <div class="relative grid shrink-0 grow grid-cols-1 gap-5 p-5 lg:grid-cols-[80px_5fr_4fr]">
        <FormSteps :steps />

        <div class="absolute top-5 right-18 lg:hidden">
            <i class="block size-16 bg-[url(/assets/wnl/logo_square_full-color.svg)] bg-contain bg-center bg-no-repeat"></i>
        </div>

        <DismissButton class="absolute max-lg:top-8 max-lg:right-5" @click="closeForm" />

        <div class="z-50 flex flex-col">
            <h2 class="text-wnl-orange -mt-2 mb-2 text-4xl font-bold">{{ title }}</h2>

            <p v-if="description" class="text-wnl-gray-100 mb-4">{{ description }}</p>

            <slot />
        </div>

        <div class="z-10 hidden items-center justify-center lg:flex">
            <img
                class="isolate aspect-square w-[calc(100%-32px)] max-w-96 rounded-full object-cover object-center shadow-[0_0_0_30px_var(--color-white),0_0_0_42px_var(--color-wnl-orange)]"
                src="/assets/wnl/steunwnl-header.jpg"
                alt="WNL Team"
            />
        </div>
    </div>
</template>
