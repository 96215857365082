<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import Form from '@app/components/settings/finance/resultRewardProducts/Form.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import Settings from '@app/layouts/Settings.vue'
import { type LocationValuation, type ResultRewardProduct } from '@app/types/finance'
import type { LocationType, Product, ResourceCollection } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { sortBy, uniqBy } from 'lodash'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { products, selectedResultRewardProduct } = defineProps<{
    resultRewardProducts: ResourceCollection<ResultRewardProduct>
    products: ResourceCollection<Product>
    locationTypes: ResourceCollection<LocationType>
    locationValuations: ResourceCollection<LocationValuation>
    selectedResultRewardProduct: ResultRewardProduct | null
}>()

const { toggleCard } = useDetailCard(selectedResultRewardProduct, 'selectedResultRewardProduct')
const selectableClientProjects = computed(() => {
    const clientProjects = products.map((product: Product) => product.client_project)

    return sortBy(uniqBy(clientProjects, 'slug'), 'slug')
})

const columns = [
    {
        type: 'string',
        header: 'attributes.product',
        properties: { name: 'name', relation: 'product' },
    },
    {
        type: 'string',
        header: 'attributes.location_type',
        properties: { name: 'name', relation: 'location_type' },
    },
    {
        type: 'string',
        header: 'attributes.location_valuation',
        properties: { name: 'name', relation: 'location_valuation', translate: true },
    },
    {
        type: 'string',
        header: 'attributes.reward_on_order_status',
        properties: { name: 'name', relation: 'order_status', translate: true },
    },
    {
        type: 'amount',
        header: 'attributes.shift_member_amount',
        properties: { name: 'shift_member_amount' },
        sort: 'shift_member_amount',
    },
    {
        type: 'amount',
        header: 'attributes.fonky_amount',
        properties: { name: 'fonky_amount' },
        sort: 'fonky_amount',
    },
    {
        type: 'date',
        header: 'attributes.amount_start_date',
        properties: { name: 'amount_start_date' },
        sort: 'amount_start_date',
    },
]

function closeForm() {
    applyFilter({
        selected: null,
    })
}
</script>

<template>
    <Settings group="finance">
        <TwoPaneLayout>
            <DataTable
                class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
                :items="resultRewardProducts"
                :columns="columns"
                only="resultRewardProducts"
                :clickable="true"
                :activeId="selectedResultRewardProduct?.id"
                @row:clicked="(item) => toggleCard(item.id)"
            >
                <template #filter>
                    <FilterSection>
                        <DropdownMultiFilter
                            :items="selectableClientProjects"
                            :label="$t('attributes.client_project')"
                            propertyName="name"
                            slug="client_project"
                            by="slug"
                            :translateDb="false"
                        />
                        <DropdownMultiFilter
                            :items="products"
                            :label="$t('attributes.product')"
                            propertyName="name"
                            slug="product"
                            by="id"
                            :translateDb="false"
                        />
                        <DropdownMultiFilter
                            :items="locationTypes"
                            :label="$t('attributes.location_type')"
                            propertyName="name"
                            slug="location_type"
                            by="slug"
                            :translateDb="false"
                        />
                        <DropdownMultiFilter
                            :items="locationValuations"
                            :label="$t('attributes.location_valuation')"
                            propertyName="name"
                            slug="location_valuation"
                            by="slug"
                        />
                        <ResetFilterButton class="mt-2" :href="route('settings.finance.result-reward-product.index')" :label="$t('buttons.clear')" />
                    </FilterSection>
                </template>
            </DataTable>
            <template #detail v-if="selectedResultRewardProduct">
                <Card @close="closeForm" :dismissable="true">
                    <Form :resultRewardProduct="selectedResultRewardProduct" :key="selectedResultRewardProduct?.id" @submit="closeForm" />
                </Card>
            </template>
        </TwoPaneLayout>
    </Settings>
</template>
