<script setup lang="ts">
import type { ShiftAvailabilityEvent } from '@app/types/shifts'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { format } from 'date-fns'
import { computed } from 'vue'

const { availability, selected = false } = defineProps<{
    availability: ShiftAvailabilityEvent
    selected?: boolean
}>()

const emit = defineEmits(['click'])

const from = computed(() => format(new Date(availability.from), 'HH:mm'))
const to = computed(() => format(new Date(availability.to), 'HH:mm'))

const description = computed(() =>
    [availability.branch_name, availability.work_type_slug !== 'contact-center' ? availability.location_type_name : null]
        .filter((item) => item !== null)
        .join(' / ')
)
</script>

<template>
    <div class="relative flex flex-1 cursor-pointer p-1 transition-all duration-200 hover:brightness-90" @click="$emit('click')">
        <div
            class="group scrollbar-hide flex w-full items-start gap-2 overflow-hidden rounded-lg px-1 py-1 text-xs font-medium whitespace-nowrap uppercase"
            :class="{
                'outline-2': selected,
            }"
            :style="{
                background: `var(--color-${availability.client_color_code}-50)`,
                color: `var(--color-${availability.client_color_code}-700)`,
                outlineColor: `var(--color-${availability.client_color_code}-400)`,
            }"
            :title="availability.client_project_name + ' - ' + availability.location_type_name + ' - ' + from + ' to ' + to"
        >
            <div
                class="left-2 h-full w-[5px] flex-shrink-0 rounded"
                :style="{ background: availability.shift_id ? `var(--color-${availability.client_color_code}-500)` : 'none' }"
            ></div>
            <div class="flex flex-col">
                <div class="flex h-4 w-full items-center justify-between">
                    <time class="flex flex-grow flex-col font-light">{{ from }} - {{ to }}</time>
                    <ExclamationCircleIcon
                        v-if="['should-have-started', 'no-show', 'sick'].includes(availability.shift_member_status)"
                        class="ml-auto h-5 w-5"
                    />
                </div>
                <p class="text-ellipsis">{{ availability.client_project_name }}</p>
                <p v-if="description" class="font-light text-ellipsis italic">{{ description }}</p>
            </div>
        </div>
    </div>
</template>
