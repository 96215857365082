<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import VueDatePicker from '@vuepic/vue-datepicker'

const { getLocale } = useLocale()
</script>

<template>
    <VueDatePicker
        :locale="getLocale().code"
        :auto-position="false"
        auto-apply
        :enable-time-picker="false"
        text-input
        :text-input-options="{
            format: 'dd-MM-yyyy',
        }"
        format="dd-MM-yyyy"
    />
</template>
