<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftAvailability } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { computed } from 'vue'

const { availability } = defineProps<{
    availability: ShiftAvailability
}>()

const { dbTranslate } = useLocale()

const from = computed(() => getTimeFromDateTime(availability.from))
const to = computed(() => getTimeFromDateTime(availability.to))
const date = computed(() => getDateFromDateTime(availability.from))
</script>

<template>
    <div class="mt-4 flex w-full justify-start gap-4 text-left">
        <div class="grid w-full grid-cols-2 gap-4">
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.full_name') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ availability.user_full_name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.client_project') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ availability.client_project.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.date') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ date }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.time') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ from }} - {{ to }}</span>
            </div>
            <div v-if="availability.branch" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.branch') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ availability.branch.name }}</span>
            </div>
            <div v-if="availability.location_type" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.location_type') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ availability.location_type.name }}</span>
            </div>
        </div>
    </div>
</template>
