import type { PageProps } from '@app/types/inertia'
import type { TranslatableProperty } from '@app/types/shared'
import { usePage } from '@inertiajs/vue3'
import { enUS, nl } from 'date-fns/locale'
import { trans } from 'laravel-vue-i18n'
import { computed } from 'vue'

type Translatable = TranslatableProperty | string

function isTranslatableProperty(property: Translatable): property is TranslatableProperty {
    return typeof property === 'object'
}

export function useLocale() {
    const page = usePage<PageProps>()
    const language = computed(() => page.props.userLanguage?.slug || 'nl')

    function getLocale() {
        switch (language.value) {
            case 'en':
                return enUS
            default:
                return nl
        }
    }

    function dbTranslate(property?: TranslatableProperty) {
        return property?.[language.value] || ''
    }

    function translate(property: Translatable) {
        if (isTranslatableProperty(property)) {
            return dbTranslate(property)
        }

        return trans(property)
    }

    function translateIfExists(property: string, translationPath: string) {
        const translated = trans(`${translationPath}.${property}`)

        if (translated.startsWith(translationPath)) {
            return property
        }

        return translated
    }

    function initializeEmptyTranslatablePropertyModel() {
        return page.props.languages.reduce<TranslatableProperty>((prev, language) => {
            prev[language.slug] = ''

            return prev
        }, {})
    }

    return { getLocale, dbTranslate, initializeEmptyTranslatablePropertyModel, translate, translateIfExists }
}
