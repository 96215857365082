<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'

const { active = false, sortable = false } = defineProps<{ active?: boolean; sortable?: boolean }>()

const emit = defineEmits(['sort'])
</script>

<template>
    <div
        class="transition-color flex items-center justify-between bg-white px-4 py-3 pb-3 pt-2 duration-200 md:flex-row"
        :class="active ? 'bg-zinc-50' : 'bg-white'"
        @click="emit('sort')"
    >
        <div class="flex items-center gap-2">
            <slot></slot>
        </div>

        <template v-if="sortable">
            <ChevronDownIcon v-if="active" class="transition-color text-primary-600 size-5 cursor-pointer duration-200" />
            <ChevronUpIcon v-else class="transition-color size-5 cursor-pointer text-zinc-300 duration-200" />
        </template>
    </div>
</template>
