<script setup lang="ts">
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import { useShiftPlanning } from '@app/composables/shifts/useShiftsPlanning'
import type { PlanningShift, ShiftAvailabilityEvent, ShiftTemplate } from '@app/types/shifts'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { getUniqueModelsFromArray } from '@app/utils/object'
import { ChevronDownIcon, PencilSquareIcon, TruckIcon, UsersIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import { isSameDay, parseISO } from 'date-fns'
import { computed, ref, watch } from 'vue'

const emit = defineEmits(['shift:remove', 'shift-member:add'])

const model = defineModel<PlanningShift>({ required: true })

const { multiple } = defineProps<{
    multiple: boolean
}>()

const { draggingAvailability } = useShiftPlanning()

const collapsed = ref(false)
const noteFieldShown = ref(false)

const userShiftTemplates = computed(() => usePage().props.userShiftTemplates as ShiftTemplate[])
const mainTeamMember = computed(() => model.value.availabilities[0])

const selectableLocationTypes = computed(() => {
    if (mainTeamMember.value.location_type_id) {
        return []
    }

    const clientProjectShiftTemplates = userShiftTemplates.value.filter((item) => item.client_project_id === mainTeamMember.value.client_project_id)

    return getUniqueModelsFromArray(clientProjectShiftTemplates, 'location_type')
})

const mainTeamMemberFrom = computed(() => parseISO(mainTeamMember.value.from))
const mainTeamMemberTo = computed(() => parseISO(mainTeamMember.value.to))
const mainTeamMemberShiftSettings = computed(() => {
    return [mainTeamMember.value.client_project_name, mainTeamMember.value.branch_name].filter((item) => item !== null).join(' - ')
})

function isAllowed(availability: ShiftAvailabilityEvent) {
    const isSameWorkingRange =
        mainTeamMember.value.client_project_id === availability.client_project_id &&
        mainTeamMember.value.branch_id === availability.branch_id &&
        (availability.location_type_id === null || mainTeamMember.value.location_type_id === availability.location_type_id)

    if (!isSameWorkingRange) {
        return false
    }

    const availabilityFrom = parseISO(availability.from)
    const availabilityTo = parseISO(availability.to)

    return isSameDay(mainTeamMemberFrom.value, availabilityFrom) && availabilityFrom <= mainTeamMemberFrom.value && availabilityTo >= mainTeamMemberTo.value
}

function toggleCollapsed() {
    if (multiple) {
        collapsed.value = !collapsed.value
    }
}

const mode = ref('normal')

watch(draggingAvailability, () => {
    if (draggingAvailability.value && !isAllowed(draggingAvailability.value)) {
        mode.value = 'forbidden'
    } else {
        mode.value = 'normal'
    }
})

const title = computed(() => {
    if (multiple) {
        return `Team ${model.value.availabilities.map((item) => item.user_full_name).join(', ')}`
    }

    return mainTeamMember.value.user_full_name
})

function handleShiftMemberAdd(event: DragEvent) {
    event.preventDefault()

    if (!draggingAvailability.value) {
        return
    }

    if (isAllowed(draggingAvailability.value)) {
        model.value = {
            ...model.value,
            availabilities: [...model.value.availabilities, draggingAvailability.value],
        }
    }
}

function handleShiftMemberRemove(availabilityId: number) {
    const availabilities = model.value.availabilities.filter((availability) => availability.id !== availabilityId)

    if (availabilities.length === 0) {
        emit('shift:remove')
    } else {
        model.value = {
            ...model.value,
            availabilities,
        }
    }
}

function onDragOver(event: DragEvent) {
    if (draggingAvailability.value && isAllowed(draggingAvailability.value)) {
        event.preventDefault()
    }
}

function handleShiftDragOver() {
    if (collapsed.value && draggingAvailability.value) {
        collapsed.value = false
    }
}
</script>

<template>
    <div class="relative flex flex-col justify-between gap-2 px-2 py-4" @dragover.prevent="handleShiftDragOver">
        <div class="flex items-center justify-between gap-2" :class="{ 'cursor-pointer': multiple }" @click="toggleCollapsed">
            <UsersIcon v-if="multiple" class="size-5 text-zinc-700" />
            <img v-else class="size-6 rounded-full" :src="mainTeamMember.user_avatar" :alt="mainTeamMember.user_full_name" />

            <p class="flex-1 text-sm font-medium text-zinc-800">{{ title }}</p>
            <ChevronDownIcon v-if="multiple" class="size-6 text-zinc-800 transition-all duration-300" :class="[!collapsed ? 'rotate-180' : '']" />
            <XMarkIcon v-else class="size-5 cursor-pointer" @click="$emit('shift:remove')" />
        </div>

        <div class="flex items-center gap-2 text-xs text-zinc-500">
            <FromToDate :from="mainTeamMember.from" :to="mainTeamMember.to" /> |
            <p>{{ mainTeamMemberShiftSettings }}</p>
        </div>
        <transition
            enter-active-class="transition-all duration-400 ease-out overflow-hidden"
            leave-active-class="transition-all duration-400 ease-in overflow-hidden"
            enter-from-class="max-h-0 opacity-0"
            enter-to-class="max-h-[500px] opacity-100"
            leave-from-class="max-h-[500px] opacity-100"
            leave-to-class="max-h-0 opacity-0"
        >
            <div v-if="multiple" v-show="!collapsed" class="flex flex-col gap-2">
                <!-- <div class="group text-primary-500 my-1 flex w-fit cursor-pointer items-center justify-end gap-2 text-sm font-medium transition">
                    <MapPinIcon class="text-primary-400 group-hover:text-primary-700 size-6" title="Set location" />
                    <p class="text-primary-500 group-hover:text-primary-700">Set location</p>
                </div> -->
                <Select
                    v-if="selectableLocationTypes.length > 0"
                    v-model="model.location_type_id"
                    autoselect
                    :placeholder="translatedSelectPlaceholder('attributes.location_type')"
                >
                    <SelectOption v-for="locationType in selectableLocationTypes" :value="locationType.id" :label="locationType.name" :key="locationType.id" />
                </Select>
                <div
                    class="flex min-h-[60px] flex-wrap gap-2 rounded-lg border p-2"
                    @dragover="onDragOver"
                    @drop="handleShiftMemberAdd"
                    :class="[mode === 'forbidden' ? 'border-red-200 bg-red-50' : 'border-zinc-200 bg-zinc-50']"
                >
                    <div
                        v-for="member in model.availabilities"
                        :key="member.id"
                        class="flex items-center gap-2 rounded-lg border border-zinc-100 bg-white py-2 pr-2 pl-4"
                    >
                        <p class="text-sm leading-5 font-medium">{{ member.user_full_name }}</p>

                        <span v-if="member.has_drivers_license" :title="$t('attributes.has_drivers_license')">
                            <TruckIcon class="text-primary-300 size-4" :title="$t('attributes.has_drivers_license')" />
                        </span>
                        <XMarkIcon class="size-4 cursor-pointer text-zinc-500 transition hover:text-zinc-700" @click="handleShiftMemberRemove(member.id)" />
                    </div>
                </div>
                <div
                    class="group text-primary-500 mt-1 flex cursor-pointer justify-end gap-2 text-xs font-medium transition"
                    @click="noteFieldShown = !noteFieldShown"
                >
                    <PencilSquareIcon v-if="!noteFieldShown" class="group-hover:text-primary-700 size-4" />
                    <p v-if="!noteFieldShown" class="group-hover:text-primary-700">Add a note</p>
                    <p v-if="noteFieldShown" class="group-hover:text-primary-700">Cancel</p>
                </div>
                <FormTextArea v-if="noteFieldShown" v-model="model.note" @close="noteFieldShown = false" :rows="3" autocomplete="off" autofocus />
            </div>
        </transition>
    </div>
</template>
