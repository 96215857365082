<script setup lang="ts">
import { ArrowPathIcon } from '@heroicons/vue/20/solid'
import SignaturePad from 'signature_pad'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Button from '../button/Button.vue'
import Label from './Label.vue'

const props = withDefaults(
    defineProps<{
        label?: string
        modelValue: string | null
        penColor?: string
    }>(),
    {
        penColor: '#5a5aa8',
    }
)

const emit = defineEmits<{
    (e: 'update:modelValue', value: typeof props.modelValue): void
}>()

const canvas = ref()
let pad: SignaturePad

// handle DPI issue with canvas
function resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    const maxHeight = Math.min(canvas.value.offsetHeight, 160)

    canvas.value.width = canvas.value.offsetWidth * ratio
    canvas.value.height = maxHeight * ratio

    canvas.value.getContext('2d').scale(ratio, ratio)

    if (props.modelValue) {
        pad.fromDataURL(props.modelValue)
    } else {
        pad.fromData(pad.toData())
    }
}

onMounted(() => {
    if (canvas.value) {
        pad = new SignaturePad(canvas.value, {
            penColor: props.penColor,
        })
        pad.addEventListener('endStroke', () => {
            emit('update:modelValue', pad.toDataURL())
        })

        window.addEventListener('resize', resizeCanvas)
    }

    resizeCanvas()
})

onUnmounted(() => {
    window.removeEventListener('resize', resizeCanvas)
})

watch(
    () => props.modelValue,
    () => {
        pad.clear()

        if (props.modelValue) {
            pad.fromDataURL(props.modelValue)
        }
    }
)

function handleClearButtonClick() {
    pad?.clear()
    emit('update:modelValue', null)
}

watch(
    () => props.penColor,
    (newPenColor) => {
        if (pad) {
            pad.penColor = newPenColor
        }
    }
)
</script>

<template>
    <div>
        <Label v-if="label">{{ label }}</Label>
        <canvas ref="canvas" class="shadow-xs mt-1 w-full rounded-md border border-gray-300 p-1"></canvas>
        <Button type="button" @click="handleClearButtonClick" color="white" class="mt-4">
            <ArrowPathIcon class="h-5 w-5" aria-hidden="true" />
            {{ $t('buttons.clear') }}
        </Button>
    </div>
</template>
