import type { Model } from '@app/types/shared'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

/**
 *
 * Add to object if it does not yet exist, remove otherwise.
 */
export function addOrRemoveValueFromObject(object: Object, key: any, value: any): Object {
    if (object[key as keyof typeof object]) {
        delete object[key as keyof typeof object]
    } else {
        object[key as keyof typeof object] = value
    }

    return object
}

export function getUniqueModelsFromArray<T extends Model, K extends keyof T>(models: T[], propertyName: K) {
    const items = models.map((model) => model[propertyName]).filter((item) => item !== null)

    return sortBy(uniqBy(items, 'id'), 'name')
}

export function sortObjectByKey<T extends Object, K extends keyof T>(object: Record<string, T>, property: K) {
    return Object.fromEntries(Object.entries(object).sort(([, a], [, b]) => a[property] - b[property]))
}
