<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import PersonalAvailabilityForm from '@app/components/shifts/availabilities/forms/PersonalAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import ShiftMemberCard from '@app/components/shifts/shifts/ShiftMemberCard.vue'
import Button from '@app/components/ui/button/Button.vue'
import Body from '@app/components/ui/calendar/body/Body.vue'
import TimeGrid from '@app/components/ui/calendar/body/TimeGrid.vue'
import TimeSlot from '@app/components/ui/calendar/body/TimeSlot.vue'
import Calendar from '@app/components/ui/calendar/Calendar.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import { useShiftAvailabilityEvent } from '@app/composables/shifts/useShiftAvailabilityEvent'
import type { Shift, ShiftAvailability, ShiftAvailabilityEvent as ShiftAvailabilityEventType, ShiftMember, ShiftTemplate } from '@app/types/shifts'
import { eachDayOfInterval, endOfWeek, getHours, isPast, parseISO, startOfWeek } from 'date-fns'
import { computed, ref } from 'vue'

const { selectedShift, selectedAvailability, selectedShiftMember, userShiftTemplates, availabilities } = defineProps<{
    availabilities: ShiftAvailabilityEventType[]
    userShiftTemplates: ShiftTemplate[]
    selectedShift: Shift | null
    selectedAvailability: ShiftAvailability | null
    selectedShiftMember: ShiftMember | null
}>()

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['availabilities'] })

const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))
const openAvailabilityForm = ref(false)

const { toggleAvailability, closeCards, isEventSelected } = useShiftAvailabilityEvent()

const timeGridBoundaries = computed(() => {
    const availabilityHours = availabilities.flatMap((item) => {
        const from = getHours(parseISO(item.from))
        const to = getHours(parseISO(item.to))

        // convert 0 to 24 for end time
        return [from, to === 0 ? 24 : to]
    })

    const defaultStart = 9
    const defaultEnd = 22

    return {
        start: Math.min(defaultStart, ...availabilityHours),
        end: Math.max(defaultEnd, ...availabilityHours),
    }
})
</script>

<template>
    <ModalLayout :show="openAvailabilityForm" @close="openAvailabilityForm = false">
        <PersonalAvailabilityForm :date="selectedDay" :user-shift-templates="userShiftTemplates" @close="openAvailabilityForm = false" />
    </ModalLayout>
    <TwoPaneLayout class="mt-2">
        <Calendar :days class="flex w-full flex-col transition-all duration-500">
            <Header :date="selectedDay">
                <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
                <Divider />
                <Button :disabled="isPastWeek" @click="openAvailabilityForm = true">
                    {{ $t('calendar.availability.add') }}
                </Button>
            </Header>

            <Body>
                <TimeGrid :start="timeGridBoundaries.start" :end="timeGridBoundaries.end">
                    <TimeSlot v-for="availability in availabilities" :from="availability.from" :to="availability.to">
                        <ShiftAvailabilityEvent
                            :availability
                            @click="toggleAvailability(availability)"
                            :selected="
                                availability.shift_id
                                    ? isEventSelected(availability.shift_id, 'selected_shift')
                                    : isEventSelected(availability.id, 'selected_availability')
                            "
                        />
                    </TimeSlot>
                </TimeGrid>
            </Body>
        </Calendar>
        <template #detail v-if="selectedAvailability">
            <AvailabilityCard @close="closeCards" :availability="selectedAvailability" />
        </template>
        <template #detail v-else-if="selectedShift">
            <ShiftCard @close="closeCards" :shift="selectedShift" />
        </template>
        <template #detail v-else-if="selectedShiftMember">
            <ShiftMemberCard :shift-member="selectedShiftMember" @close="closeCards" />
        </template>
    </TwoPaneLayout>
</template>
