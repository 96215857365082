import type { ShiftAvailabilityEvent } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { route } from 'ziggy-js'

export function useShiftAvailabilityEvent() {
    function isEventSelected(id: number, paramName: string) {
        const param = route().queryParams[paramName]

        if (!param) {
            return false
        }

        return parseInt(param.toString()) === id
    }

    function handleAvailabilityClick(id: number) {
        if (isEventSelected(id, 'selected_availability')) {
            closeCards()
        } else {
            applyFilter(
                { selected_shift: null, selected_availability: id, selected_shift_member: null },
                { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
            )
        }
    }

    function handleShiftClick(id: number) {
        if (isEventSelected(id, 'selected_shift')) {
            closeCards()
        } else {
            applyFilter(
                { selected_shift: id, selected_availability: null, selected_shift_member: null },
                { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
            )
        }
    }

    function closeCards() {
        applyFilter(
            { selected_shift: null, selected_availability: null, selected_shift_member: null },
            { only: ['selectedShift', 'selectedAvailability', 'selectedShiftMember'] }
        )
    }

    function toggleAvailability(availability: ShiftAvailabilityEvent) {
        if (availability.shift_id) {
            handleShiftClick(availability.shift_id)
        } else {
            handleAvailabilityClick(availability.id)
        }
    }

    return {
        isEventSelected,
        toggleAvailability,
        closeCards,
    }
}
