<script setup lang="ts">
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const { type = 'info' } = defineProps<{ type?: 'info' | 'warning' | 'error' | 'success' }>()

const bg = computed(() => {
    switch (type) {
        case 'success':
            return 'bg-green-50'
        case 'error':
            return 'bg-red-50'
        case 'warning':
            return 'bg-orange-50'
        default:
            return 'bg-secondary-50'
    }
})

const titleColor = computed(() => {
    switch (type) {
        case 'success':
            return 'text-green-800'
        case 'error':
            return 'text-red-800'
        case 'warning':
            return 'text-orange-800'
        default:
            return 'text-secondary-800'
    }
})

const textColor = computed(() => {
    switch (type) {
        case 'success':
            return 'text-green-700'
        case 'error':
            return 'text-red-700'
        case 'warning':
            return 'text-orange-700'
        default:
            return 'text-secondary-700'
    }
})
</script>

<template>
    <div class="flex gap-4 rounded-md p-4 text-sm leading-5" :class="bg">
        <div class="w-4">
            <InformationCircleIcon v-if="type === 'info'" class="text-secondary-400 block size-5" />
            <InformationCircleIcon v-if="type === 'warning'" class="block size-5 text-orange-400" />
            <XCircleIcon v-if="type === 'error'" class="block size-5 text-red-400" />
            <CheckCircleIcon v-if="type === 'success'" class="block size-5 text-green-400" />
        </div>

        <div>
            <p v-if="$slots.title" class="mb-2 font-medium" :class="titleColor">
                <slot name="title"></slot>
            </p>

            <p :class="textColor"><slot></slot></p>
        </div>
    </div>
</template>
