<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Authenticatable } from '@app/types/shared'
import { ArrowRightEndOnRectangleIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { can, isSuperAdmin, isSameUser } = useUserCan()

const { user } = defineProps<{ user: Authenticatable }>()

const show = computed(() => !user.deleted_at && can('auth.admin.login-as-user') && !isSuperAdmin(user) && !isSameUser(user))

const form = useForm({
    user_type: user.type,
})

function loginAsUser() {
    form.post(route('user.admin.login-as-user', user.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('navigation.login_as_user')" @click="loginAsUser">
        <ArrowRightEndOnRectangleIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
