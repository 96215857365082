import type { ClientUser, User } from '@app/types/shared'
import type { ShiftHour, ShiftTemplate } from '@app/types/shifts'
import { getUniqueModelsFromArray } from '@app/utils/object'
import { useForm } from '@inertiajs/vue3'
import { computed, watch, type ComputedRef } from 'vue'
import { route } from 'ziggy-js'

export function useCreateShiftAvailabilityForm(userShiftTemplates: ShiftTemplate[], user: User | ClientUser) {
    const form = useForm({
        user: user,
        client_project_id: user.client_project_id,
        location_type_id: null,
        branch_id: null,
        shift_availabilities: {},
    })

    const clientProjects = computed(() => getUniqueModelsFromArray(userShiftTemplates, 'client_project'))

    const selectedWorkTypeSlug = computed(() => {
        if (form.client_project_id) {
            return clientProjects.value.find((clientProject) => clientProject.id === form.client_project_id)?.work_type.slug
        }

        return null
    })

    const selectableLocationTypes = computed(() => {
        let templates = userShiftTemplates.filter((shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id)

        return getUniqueModelsFromArray(templates, 'location_type')
    })

    const showLocationTypesDropdown = computed(() => selectedWorkTypeSlug.value !== 'promotions' && selectableLocationTypes.value.length > 0)

    const selectableBranches = computed(() => {
        let templates = userShiftTemplates.filter(
            (shiftTemplate) => shiftTemplate.client_project_id === form.client_project_id && shiftTemplate.location_type_id === form.location_type_id
        )

        return getUniqueModelsFromArray(templates, 'branch')
    })

    const selectedShiftTemplate = computed(() =>
        userShiftTemplates.find(
            (template) =>
                template.client_project_id === form.client_project_id &&
                (form.location_type_id === null || template.location_type_id === form.location_type_id) &&
                (template.branch_id === null || template.branch_id === form.branch_id)
        )
    )

    watch(selectedWorkTypeSlug, () => {
        if (selectedWorkTypeSlug.value === 'promotions') {
            form.branch_id = null
            form.location_type_id = null
        }

        form.shift_availabilities = {}
    })

    const selectableShiftHours = computed(() => selectedShiftTemplate.value?.shift_hours ?? []) as ComputedRef<ShiftHour[]>

    function submit(onSuccess: () => void) {
        form.post(route('shifts.availability.store'), {
            preserveScroll: true,
            onSuccess,
            onError: () => {
                let keys = Object.entries(form.errors)

                keys.forEach(([key, message]) => {
                    if (key.startsWith('shift_availabilities.')) {
                        const [_, index] = key.split('.')

                        if (!form.errors.shift_availabilities) {
                            form.errors.shift_availabilities = {}
                        }

                        form.errors.shift_availabilities[index] = message
                    }
                })
            },
        })
    }

    return {
        form,
        selectedWorkTypeSlug,
        clientProjects,
        selectableLocationTypes,
        showLocationTypesDropdown,
        selectableBranches,
        selectableShiftHours,
        submit,
    }
}
