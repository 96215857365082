import { format, isSameDay, parse, type DateArg } from 'date-fns'
import { normalizeTimeString } from './date'

function isStaticHoliday(date: string) {
    return /^\d{2}\-\d{2}$/.test(date)
}

function parseExcludedDate(date: string, targetDate: Date) {
    if (isStaticHoliday(date)) {
        return parse(date, 'MM-dd', targetDate)
    }

    return parse(date, 'yyyy-MM-dd', new Date())
}

/**
 * Determine whether excluded dates from the OpeningHours package include the provided date.
 */
export function isExcluded(targetDate: Date, excludedDates: string[]) {
    return excludedDates.some((item) => {
        const parsedDate = parseExcludedDate(item, targetDate)

        return isSameDay(parsedDate, targetDate)
    })
}

function dateToWeekday(date: DateArg<Date>): WeekdayKey {
    return format(date, 'EEEE').toLowerCase() as WeekdayKey
}

type WeekdayKey = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

type WeekdaySchedule = {
    [K in WeekdayKey]: string[]
}

type OpeningHoursExceptions = {
    exceptions: {
        [key: string]: string[]
    }
}

export type OpeningHoursState = WeekdaySchedule & OpeningHoursExceptions

export class OpeningHours {
    constructor(private state: OpeningHoursState) {}

    isClosedAt(date: DateArg<Date>) {
        const weekday = dateToWeekday(date)

        if (this.state[weekday].length === 0) {
            return true
        }

        return isExcluded(date, Object.keys(this.state.exceptions))
    }

    isTimeWithinOpeningHours(date: DateArg<Date>, startTime: string, endTime: string) {
        const weekday = dateToWeekday(date)

        if (this.isClosedAt(date)) {
            return false
        }

        return this.state[weekday].some((interval) => {
            const [openTime, closeTime] = interval.split('-')

            return normalizeTimeString(openTime) <= normalizeTimeString(startTime) && normalizeTimeString(closeTime) >= normalizeTimeString(endTime)
        })
    }
}
