<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import { KeyIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const { user } = defineProps<{ user: User }>()

const { isSameUser, isSuperAdmin, loggedInUser } = useUserCan()

const show = computed(() => !user.deleted_at && isSuperAdmin(loggedInUser) && !isSameUser(user))
const label = computed(() => (isSuperAdmin(user) ? 'navigation.remove_admin' : 'navigation.grant_admin'))

const form = useForm({
    user_type: user.type,
})

function toggleAdmin() {
    const action = isSuperAdmin(user) ? 'user.admin.remove-admin' : 'user.admin.grant-admin'

    form.post(route(action, user.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t(label)" @click="toggleAdmin">
        <KeyIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
