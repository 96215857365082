<script setup lang="ts">
import ContractForm from '@app/components/contracts/forms/ContractForm.vue'
import Alert from '@app/components/ui/alert/Alert.vue'
import type { Contract, ContractModel, GeneratedFiles } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { ref, watch } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])
const props = defineProps<{ model: Contract & GeneratedFiles }>()

const readonlyFields = ref(['email'])

const form = useForm<ContractModel>({
    phase: props.model.phase,
    branch_id: props.model.branch_id,
    contract_hour_type_id: props.model.contract_hour_type_id,
    email: props.model.email,
    phone_number: props.model.phone_number,
    job_title_id: props.model.job_title.id,
    contract_job_title: props.model.contract_job_title,
    gross_monthly_salary: props.model.gross_monthly_salary.amount,
    min_hours: props.model.min_hours,
    max_hours: props.model.max_hours,
    sign_by: props.model.sign_by,
    start_date: props.model.start_date ? new Date(props.model.start_date) : null,
    end_date: props.model.end_date ? new Date(props.model.end_date) : null,
    trial_period: props.model.trial_period,
    include_phone_allowance: !!props.model.phone_allowance_amount,
    phone_allowance_amount: props.model.phone_allowance_amount?.amount || null,
    retirement_provision: props.model.retirement_provision || null,
    involves_visiting_other_locations: props.model.involves_visiting_other_locations,
    business_entity: props.model.business_entity || '',
    send_code_of_conduct: false,
})

if (!props.model.end_date) {
    readonlyFields.value = [...readonlyFields.value, 'end_date']
}

watch(
    () => form.new_phase,
    (isNewPhase) => {
        if (props.model.phase === 3 && isNewPhase) {
            readonlyFields.value.push('end_date')
            form.end_date = null
        }
    }
)
</script>

<template>
    <div>
        <Alert>{{ $t('contract.change_contract_hint') }}</Alert>
        <ContractForm
            class="mt-2"
            :form
            :action="route('contract.change', props.model.id)"
            @content:closed="$emit('content:closed')"
            :readonly="readonlyFields"
        />
    </div>
</template>
