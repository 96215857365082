<script setup lang="ts">
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Order } from '@app/types/orders'
import { getZonedDateTime } from '@app/utils/date'

defineProps<{ order: Order }>()

const { dbTranslate, translateIfExists } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full flex-wrap justify-start gap-4 text-left">
        <div class="mr-6 flex flex-col justify-start space-y-2">
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.status') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ dbTranslate(order.status.name) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.client_project') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ order.client_project.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.branch_id') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ order.branch.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.application') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ order.application.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.ordered_at') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ getZonedDateTime(order.ordered_at) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.created_by') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ order.creator.full_name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs leading-4 font-normal text-gray-400 uppercase">{{ $t('attributes.external_id') }}</span>
                <span class="text-sm leading-5 font-normal text-gray-700">{{ order.external_id }}</span>
            </div>

            <DetailItem v-for="(value, prop) in order.properties ?? {}" :label="$t(`orders.properties.${prop}`)">
                <template v-if="value">
                    {{ translateIfExists(value, `orders.property_values.${prop}`) }}
                </template>

                <template v-else>&mdash;</template>
            </DetailItem>
        </div>
    </div>
</template>
