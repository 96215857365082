<script setup lang="ts">
import { ListboxOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { inject, onMounted, onUnmounted, watch, type Ref } from 'vue'

const { value, label, disabled = false } = defineProps<{ value: string | number; label: string; disabled?: boolean }>()

const registry = inject('dropdown:registry') as Ref<Map<typeof value, string>>

watch(
    () => label,
    () => {
        registry.value.set(value, label)
    }
)

onMounted(() => {
    registry.value.set(value, label)
})

onUnmounted(() => {
    registry.value.delete(value)
})
</script>

<template>
    <ListboxOption as="template" :value v-slot="{ active, selected }" :disabled>
        <li :class="[active ? 'bg-primary-600 text-white' : 'text-zinc-900', 'relative cursor-default py-2 pr-9 pl-3 select-none']" :data-value="value">
            <div class="flex items-center">
                <span class="block truncate sm:ml-3" :class="{ 'font-semibold': selected, 'text-gray-400': disabled }">
                    <slot>
                        {{ label }}
                    </slot>
                </span>
            </div>
            <span v-if="selected" :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="size-5" aria-hidden="true" />
            </span>
        </li>
    </ListboxOption>
</template>
