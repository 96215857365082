<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import DropdownFilter from '@app/components/ui/table/filters/DropdownFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import InputFilter from '@app/components/ui/table/filters/InputFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import UserDetails from '@app/components/users/UserDetails.vue'
import UserRoleModal from '@app/components/users/UserRoleModal.vue'
import UserTypeTabs from '@app/components/users/UserTypeTabs.vue'
import EditRoles from '@app/components/users/actions/EditRoles.vue'
import EditShiftSettings from '@app/components/users/actions/EditShiftSettings.vue'
import LoginAsUser from '@app/components/users/actions/LoginAsUser.vue'
import RecoveryCodes from '@app/components/users/actions/RecoveryCodes.vue'
import ToggleAdmin from '@app/components/users/actions/ToggleAdmin.vue'
import Move from '@app/components/users/actions/recruitmentSelection/Move.vue'
import Show from '@app/components/users/actions/recruitmentSelection/Show.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import { useUserCan } from '@app/composables/useUserCan'
import type { JobTitle, ResourceCollection, Role, User } from '@app/types/shared'
import { getZonedDate, getZonedTime } from '@app/utils/date'
import { UserIcon } from '@heroicons/vue/24/solid'
import { transChoice } from 'laravel-vue-i18n'
import { computed, ref, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    users: {
        type: Object as PropType<ResourceCollection<User>>,
        required: true,
    },
    job_titles: {
        type: Array as PropType<JobTitle[]>,
        required: true,
    },
    roles: {
        type: Array as PropType<Role[]>,
        required: true,
    },
    selectedUser: Object as PropType<User>,
    scope: {
        type: String as PropType<'active' | 'inactive'>,
        required: true,
    },
})

const { selectedUser } = toRefs(props)

const params = route().params

const { loggedInUser, isSuperAdmin, can } = useUserCan()
const { dbTranslate } = useLocale()
const { toggleCard } = useDetailCard(selectedUser, 'selectedUser')

const isRoleUpdateOpen = ref(false)

const showRecruitmentSelectionColumn = computed(() => props.scope === 'inactive' && can('user.recruitment-selection.store'))

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: loggedInUser.is_super_admin,
    },
    {
        type: 'multi-line-and-avatar',
        header: 'attributes.name',
        properties: {
            avatarSrc: 'profile_photo_url',
            avatarName: 'full_name',
            primaryName: 'full_name',
            secondaryName: 'email',
        },
    },
    {
        type: 'string',
        header: 'attributes.job_title_id',
        properties: { name: 'name', relation: 'job_title', translate: true },
    },
    {
        name: 'recruitment-selection',
        header: 'user.recruitment-selection.header',
        show: showRecruitmentSelectionColumn.value,
    },
    {
        type: 'string',
        header: 'attributes.phone_number',
        properties: { name: 'phone_number' },
    },
]

const roleLabel = computed(() => {
    if (!selectedUser?.value) {
        return ''
    }

    if (isSuperAdmin(selectedUser.value)) {
        return 'Admin'
    }

    return transChoice('user.roles', selectedUser.value.roles?.length || 0)
})

const recruitmentSelectionFilterItems = computed(() => [
    { slug: 1, name: 'form.labels.yes' },
    { slug: 0, name: 'form.labels.no' },
])
</script>

<template>
    <UserTypeTabs bottomBorder />
    <TwoPaneLayout class="scrollbar-hide">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedUser ? 'inline-block' : 'hidden lg:inline-block']"
            :items="users"
            :columns="columns"
            @row:clicked="(user) => toggleCard(user.id)"
            :activeId="selectedUser?.id"
            only="users"
            :clickable="true"
        >
            <template #filter>
                <FilterSection>
                    <DropdownMultiFilter :items="job_titles" :label="$t('attributes.job_title_id')" slug="job_title" by="slug" />
                    <InputFilter :item="params.name" only="users" />
                    <DropdownFilter
                        v-if="showRecruitmentSelectionColumn"
                        only="users"
                        :items="recruitmentSelectionFilterItems"
                        :label="$t('user.recruitment-selection.header')"
                        slug="entered_recruitment_selection"
                        propertyName="name"
                        by="slug"
                        :translate-db="false"
                    />

                    <ResetFilterButton :href="route('user.index', { scope: scope === 'active' ? null : scope })" />
                </FilterSection>
            </template>

            <template #column.recruitment-selection="{ item }">
                <template v-if="item.lead?.funnel.slug === 'recruitment-selection' && item.lead?.entered_funnel_at">
                    <div class="text-gray-900">
                        {{ getZonedDate(item.lead.entered_funnel_at) }}
                    </div>
                    <div class="pl-3 text-xs text-gray-500">
                        {{ getZonedTime(item.lead.entered_funnel_at) }}
                    </div>
                </template>
            </template>
        </DataTable>

        <template #detail v-if="selectedUser">
            <DetailCard v-if="selectedUser" :model="selectedUser" dismissable @close="toggleCard(selectedUser.id)">
                <div class="flex flex-col items-center gap-4">
                    <span class="w-full text-center text-lg leading-6 font-medium text-gray-900">{{ selectedUser.full_name }}</span>
                    <Badge :round="false" size="sm" v-if="selectedUser.job_title" :label="dbTranslate(selectedUser.job_title.name)" color="gray" />
                    <Badge size="lg" :color="isSuperAdmin(selectedUser) ? 'orange' : 'gray'" :label="roleLabel" />
                </div>

                <template #actions>
                    <EditRoles :user="selectedUser" @click="isRoleUpdateOpen = true" />
                    <EditShiftSettings :user="selectedUser" />
                    <LoginAsUser :user="selectedUser" />
                    <RecoveryCodes :user="selectedUser" />
                    <Move v-if="showRecruitmentSelectionColumn && selectedUser.lead_id" :user="selectedUser" />
                    <Show v-if="showRecruitmentSelectionColumn && selectedUser.lead_id" :user="selectedUser" />
                    <ToggleAdmin :user="selectedUser" />
                </template>

                <template #tabs>
                    <DetailCardTab tab="details" label="Details">
                        <template #icon>
                            <UserIcon />
                        </template>
                        <UserDetails :user="selectedUser" />
                    </DetailCardTab>
                </template>
            </DetailCard>
        </template>
    </TwoPaneLayout>
    <UserRoleModal
        v-if="selectedUser && isRoleUpdateOpen"
        :user="selectedUser"
        :roles="roles"
        :show="isRoleUpdateOpen"
        @roles:close="isRoleUpdateOpen = false"
    />
</template>
