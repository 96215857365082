<script setup lang="ts" generic="T">
const model = defineModel()

defineProps<{
    name: string
    error?: string
}>()
</script>

<template>
    <input
        class="focus:ring-wnl-purple block w-full rounded-sm border-none bg-[#f6f6f6] px-4 py-3"
        :id="name"
        :class="[!!error ? 'ring-wnl-red ring' : 'ring-none']"
        type="text"
        v-model="model"
    />
</template>
