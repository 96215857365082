<script setup lang="ts">
import { computed, useSlots } from 'vue'

const emit = defineEmits(['update:modelValue'])
const slots = useSlots()

const props = defineProps({
    modelValue: {
        type: [Boolean, Number],
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})
</script>

<template>
    <div class="flex items-center gap-2">
        <input
            v-model="value"
            type="checkbox"
            :checked="value"
            :disabled
            class="focus:border-primary-300 focus:ring-primary-600 shadow-xs rounded-sm border-zinc-300"
            :class="[disabled ? 'text-primary-400 bg-zinc-100' : 'text-primary-600']"
        />
        <label v-if="slots.default" class="text-sm font-normal leading-5"><slot></slot></label>
    </div>
</template>
