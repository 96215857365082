<script setup lang="ts">
import type { User } from '@app/types/shared'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import { route } from 'ziggy-js'

const { user } = defineProps({
    user: {
        type: Object as PropType<User>,
        required: true,
    },
})

const userRoute = computed(() => route('user.index', { name: user.full_name, selected: user.id }))
</script>

<template>
    <Link :href="userRoute" prefetch class="hidden h-8 w-8 shrink-0 md:flex">
        <img class="size-8 rounded-full" :src="user.profile_photo_url" :alt="user.full_name" />
    </Link>
    <Link :href="userRoute" prefetch class="font-medium text-zinc-900 md:ml-4">{{ user.full_name }}</Link>
</template>
