<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import ShiftsSettingsTabs from '@app/components/settings/shifts/ShiftsSettingsTabs.vue'
import Form from '@app/components/shifts/externalProjects/Form.vue'
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import type { ClientProject, ResourceCollection, WorkType } from '@app/types/shared'
import type { ShiftExternalProject } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { computed, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    external_projects: {
        type: Object as PropType<ResourceCollection<ShiftExternalProject>>,
        required: true,
    },
    clientProjects: {
        type: Array as PropType<ClientProject[]>,
        required: true,
    },
    workTypes: {
        type: Array as PropType<WorkType[]>,
        required: true,
    },
    selectedProject: {
        type: [Object, null] as PropType<ShiftExternalProject | null>,
        required: true,
    },
    create: {
        type: Boolean,
        required: true,
    },
})

const { selectedProject, create: formShown } = toRefs(props)

const { toggleCard } = useDetailCard(selectedProject, 'selectedProject')
const { dbTranslate } = useLocale()

const params = route().params

const columns = computed(() => [
    {
        name: 'name',
        header: 'attributes.name',
    },
    {
        name: 'application',
        header: 'attributes.application',
    },
    {
        name: 'work_type',
        header: 'attributes.work_type',
    },
    {
        name: 'client_project',
        header: 'attributes.client_project',
    },
    {
        name: 'location_type',
        header: 'attributes.location_type',
    },
    {
        name: 'time',
        header: 'attributes.time',
    },
])

function showCreateForm() {
    applyFilter({
        selected: null,
        create: true,
    })
}

function closeForm() {
    applyFilter({
        selected: null,
        create: null,
    })
}
</script>

<template>
    <ShiftsSettingsTabs>
        <Button @click="showCreateForm">Create</Button>
    </ShiftsSettingsTabs>

    <TwoPaneLayout>
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[selectedProject || create ? 'hidden lg:inline-block' : 'inline-block']"
            :items="external_projects"
            :columns="columns"
            only="external_projects"
            :clickable="true"
            :active-id="selectedProject?.id"
            @row:clicked="(item) => toggleCard(item.id)"
        >
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <DropdownMultiFilter
                        :items="workTypes"
                        propertyName="name"
                        :label="$t('attributes.work_type')"
                        only="external_projects"
                        slug="work_type"
                        by="slug"
                    />
                    <DropdownMultiFilter
                        :items="clientProjects"
                        propertyName="name"
                        :label="$t('attributes.client_project')"
                        only="external_projects"
                        slug="client_project"
                        by="slug"
                    />
                    <ResetFilterButton :href="route('settings.shifts.external-project.index')" />
                </FilterSection>
            </template>

            <template #column.name="{ item }">
                <p class="max-w-40 whitespace-normal">
                    {{ item.name }}
                </p>
            </template>

            <template #column.application="{ item }">
                {{ item.application.name }}
            </template>

            <template #column.work_type="{ item }">
                {{ dbTranslate(item.work_type.name) }}
            </template>

            <template #column.client_project="{ item }">
                {{ item.client_project.name }}
            </template>

            <template #column.location_type="{ item }">
                {{ item.location_type.name }}
            </template>

            <template #column.time="{ item }"> {{ item.start_time }} - {{ item.end_time }} </template>
        </DataTable>

        <template #detail v-if="formShown || selectedProject">
            <Card @close="closeForm" :dismissable="true">
                <Form :project="selectedProject" :key="selectedProject?.id" @submit="closeForm" />
            </Card>
        </template>
    </TwoPaneLayout>
</template>
