<script setup lang="ts">
import Alert from '@app/components/ui/alert/Alert.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import PhotoInput from '@app/components/ui/form/file/PhotoInput.vue'
import Form from '@app/components/ui/form/Form.vue'
import { useForm } from '@inertiajs/vue3'
import { watch } from 'vue'

const form = useForm({
    start_selfie: null,
})

watch(
    () => form.start_selfie,
    () => {
        form.clearErrors('start_selfie')
    }
)

form.clearErrors()
</script>

<template>
    <Form :form>
        <div class="flex flex-col items-center">
            <PhotoInput v-model="form.start_selfie" class="block min-h-50 w-50">
                {{ $t('shifts.start.selfie_label') }}
            </PhotoInput>

            <FieldError class="mt-2" :message="form.errors.start_selfie" />
        </div>
        <Alert>{{ $t('shifts.start.selfie_hint') }}</Alert>

        <slot v-bind="{ form }"></slot>
    </Form>
</template>
