<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import RefreshBriggsWalkerShiftsButton from '@app/components/shifts/shifts/RefreshBriggsWalkerShiftsButton.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import DropdownFutureDateFilter from '@app/components/ui/table/filters/DropdownFutureDateFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import { type Branch, type ClientProject, type ResourceCollection, type User, type WorkType } from '@app/types/shared'
import type { Shift } from '@app/types/shifts'
import { reloadPartial } from '@app/utils/inertia'
import { toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    branches: {
        type: Array as PropType<Branch[]>,
        required: true,
    },
    clientProjects: {
        type: Array as PropType<ClientProject[]>,
        required: true,
    },
    shifts: {
        type: Object as PropType<ResourceCollection<Shift>>,
        required: true,
    },
    workTypes: {
        type: Array as PropType<WorkType[]>,
        required: true,
    },
    selectedShift: Object as PropType<Shift>,
    users: Array<User>,
})

const { selectedShift } = toRefs(props)
const { toggleCard } = useDetailCard(selectedShift, 'selectedShift')

const columns = [
    {
        type: 'string',
        header: 'attributes.work_type',
        properties: { relation: 'workType', name: 'name', translate: true },
    },
    {
        type: 'string',
        header: 'attributes.client_project',
        properties: { relation: 'clientProject', name: 'name' },
    },
    {
        type: 'string',
        header: 'attributes.branch_id',
        properties: { relation: 'branch', name: 'name' },
    },
    {
        name: 'date',
        header: 'attributes.date',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
]

const emit = defineEmits(['close'])
</script>

<template>
    <TwoPaneLayout fraction="3/4">
        <DataTable
            class="table-transition flex flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :items="shifts"
            :columns="columns"
            @row:clicked="(shift: Shift) => toggleCard(shift.id)"
            :activeId="selectedShift?.id"
            only="shifts"
            :clickable="true"
        >
            <template #filter>
                <FilterSection>
                    <DropdownMultiFilter :label="$t('attributes.shift_type')" :items="workTypes" slug="workType" />
                    <DropdownMultiFilter :label="$t('attributes.branch_id')" :items="branches" slug="branch" />
                    <DropdownMultiFilter :label="$t('attributes.client_project')" :items="clientProjects" slug="clientProject" />
                    <DropdownFutureDateFilter slug="start" :label="$t('attributes.date')" />
                    <ResetFilterButton :href="route('shifts.shift.index')" />
                    <RefreshBriggsWalkerShiftsButton :only="['shifts']" />
                </FilterSection>
            </template>
            <template v-slot:column.date="{ item }">
                <FromToDate :from="item.start" :to="item.end" class="gap-2" />
            </template>
        </DataTable>

        <template #detail v-if="selectedShift">
            <ShiftCard :shift="selectedShift" @close="toggleCard(selectedShift.id)" @reload="reloadPartial(['selectedShift', 'shifts'])" />
        </template>
    </TwoPaneLayout>
</template>
