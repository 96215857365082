<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Funnel, Lead } from '@app/types/recruitment'
import { useForm } from '@inertiajs/vue3'
import { computed, inject, ref } from 'vue'
import { route } from 'ziggy-js'
import LeadRecruitmentAppointmentForm from '../forms/LeadRecruitmentAppointmentForm.vue'

const props = defineProps<{ model: Lead }>()

const funnels = inject('funnels') as Funnel[]

const restoreForm = useForm({
    funnel_id: props.model.funnel.id,
})

const currentLeadFunnel = ref(props.model.funnel)

function restoreLead() {
    restoreForm.post(route('recruitment.lead.restore', props.model.id), {
        onSuccess() {
            emit('content:closed')
        },
    })
}

const isInWorkshopFunnelBlock = props.model.funnel_block.slug === 'workshop'

const selectedFunnel = useModelSelect(funnels, currentLeadFunnel.value.id)

const emit = defineEmits(['content:closed'])

const selectedFunnelBlock = computed(() => {
    if (!props.model.funnel_block.has_appointments) {
        return props.model.funnel_block
    }

    if (isInWorkshopFunnelBlock && selectedFunnel.value?.id === currentLeadFunnel.value.id) {
        return props.model.funnel_block
    }

    // if we change the funnel, we should move the lead to the introduction phase again
    const introductionFunnelBlock = selectedFunnel.value?.blocks.find((funnelBlock) => funnelBlock.slug === 'introduction')

    return introductionFunnelBlock || selectedFunnel.value?.blocks[0]
})

const restoreWithoutAppointment = computed(() => !props.model.funnel.is_funnel_changeable || !selectedFunnelBlock.value?.has_appointments)
</script>

<template>
    <div class="mx-auto max-w-sm">
        <div class="w-full flex-col items-center px-4 py-3" id="restore_form">
            <Dropdown
                v-if="model.funnel.is_funnel_changeable"
                class="mt-1 w-full"
                :items="funnels"
                propertyName="name"
                :label="$t('recruitment.leads.outcomes.decline.select_funnel')"
                v-model="selectedFunnel"
                v-model:form="restoreForm.funnel_id"
                id="restore_funnel_dropdown"
            />

            <template v-if="selectedFunnel">
                <FormActions v-if="restoreWithoutAppointment" :class="{ 'justify-center!': !model.funnel.is_funnel_changeable }">
                    <CancelButton @click="$emit('content:closed')" />
                    <SaveButton @click="restoreLead" :disabled="restoreForm.processing" id="restore_save_button">{{
                        $t('recruitment.leads.card.actions.restore')
                    }}</SaveButton>
                </FormActions>

                <LeadRecruitmentAppointmentForm
                    v-else
                    class="mt-2 w-full"
                    :lead="model"
                    :funnel="selectedFunnel"
                    :funnelBlock="selectedFunnelBlock"
                    action="recruitment.appointment.recruitment.store"
                    @content:closed="$emit('content:closed')"
                />
            </template>
        </div>
    </div>
</template>
