<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import { useColor } from '@app/composables/useColor'
import type { shiftMemberStatusActivityFeed } from '@app/types/activity-feed'
import type { ShiftMemberStatus } from '@app/types/shifts'
import { CheckCircleIcon, ClockIcon, FaceFrownIcon, NoSymbolIcon, PlayCircleIcon, StopCircleIcon } from '@heroicons/vue/24/solid'
import { usePage } from '@inertiajs/vue3'
import { computed, inject } from 'vue'

const props = defineProps<{
    activity: shiftMemberStatusActivityFeed
}>()

const { getShiftMemberStatusColor } = useColor()

const shiftMemberStatuses = inject<ShiftMemberStatus[]>('shiftMemberStatuses')
const getStatus = (slug: string) => {
    return shiftMemberStatuses.find((status: ShiftMemberStatus) => {
        return status.slug === slug
    })
}

const oldStatus = computed(() => getStatus(props.activity.old_status))
const newStatus = computed(() => getStatus(props.activity.new_status))

const getStatusName = (status: ShiftMemberStatus) => {
    return status.name[usePage().props.userLanguage.slug]
}
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <PlayCircleIcon v-if="newStatus.slug === 'started'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <ClockIcon v-if="newStatus.slug === 'should-have-started'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <NoSymbolIcon v-if="newStatus.slug === 'no-show'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <StopCircleIcon v-if="newStatus.slug === 'ended'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <CheckCircleIcon v-if="newStatus.slug === 'approved'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            <FaceFrownIcon v-if="newStatus.slug === 'sick'" class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader :name="activity.causer_full_name" :header="$t('activities.updated_status')" :created_at="activity.created_at" />
        </template>
        <template #content>
            {{ $t('attributes.status') }}
            {{ $t('activities.updated_from') }}
            <Badge :label="getStatusName(oldStatus)" :color="getShiftMemberStatusColor(activity.old_status)" />
            {{ $t('activities.updated_to') }}
            <Badge :label="getStatusName(newStatus)" :color="getShiftMemberStatusColor(activity.new_status)" />
        </template>
    </ActivityFeedTemplate>
</template>
