<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import { useTimer } from '@app/composables/useTimer'
import type { CallAvailabilityStatus } from '@app/types/calls'
import type { PageProps } from '@app/types/inertia'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { router, usePage } from '@inertiajs/vue3'
import { computed, inject, onBeforeUnmount, onMounted, watch } from 'vue'
import { route } from 'ziggy-js'

const { dbTranslate } = useLocale()
const { seconds, startTimer, resetTimer } = useTimer()
const { connectTwilioDevice, disconnectTwilioDevice } = inject<any>('twilio')

const callAvailabilities = computed(() => usePage<PageProps>().props.callAvailabilities)
const defaultCallAvailability = computed(() => callAvailabilities.value.find(({ slug }) => slug === 'unavailable'))

const userCallAvailability = computed(() => usePage<PageProps>().props.user.call_availability || defaultCallAvailability.value)

const showCallAvailabilityButton = computed(() => callAvailabilities.value.length > 0)

function getColorByStatus(status: CallAvailabilityStatus) {
    switch (status) {
        case 'available':
            return 'bg-green-400'
        case 'unavailable':
            return 'bg-red-400'
        default:
            return 'bg-orange-400'
    }
}

// set status as unavailable when user closes the tab or refreshes the page
onMounted(() => addEventListener('beforeunload', setUnavailable))
onBeforeUnmount(() => removeEventListener('beforeunload', setUnavailable))

function setUnavailable() {
    changeCallAvailability('unavailable')
}

async function changeCallAvailability(status: CallAvailabilityStatus) {
    if (userCallAvailability.value?.slug === status) {
        return
    }

    router.post(
        route('call.availability.update'),
        { status },
        {
            only: ['user'],
        }
    )
}

watch(userCallAvailability, (newValue, oldValue) => {
    if (newValue?.slug === 'administration' && oldValue?.slug !== 'administration') {
        startTimer()
    }

    if (oldValue?.slug === 'administration' && newValue?.slug !== 'administration') {
        resetTimer()
    }

    if (newValue) {
        const unavailableStatuses: CallAvailabilityStatus[] = ['unavailable', 'break', 'coaching']

        if (unavailableStatuses.includes(newValue.slug)) {
            disconnectTwilioDevice()
        }

        if (newValue.slug === 'available') {
            connectTwilioDevice()
        }
    }
})

const SET_AVAILABLE_AFTER_SECONDS = 30

watch(seconds, async () => {
    if (seconds.value >= SET_AVAILABLE_AFTER_SECONDS) {
        await changeCallAvailability('available')
        resetTimer()
    }
})
</script>

<template>
    <Menu as="div" class="relative shrink-0" v-if="showCallAvailabilityButton && userCallAvailability">
        <div>
            <MenuButton class="flex text-sm focus:outline-hidden">
                <span class="sr-only">Call agent status menu</span>
                <div
                    class="text-fonky-black focus:outline-primary-600 inline-flex items-center space-x-2 rounded-md border border-zinc-300 py-2 pr-2 pl-4 text-sm leading-5 font-medium shadow-xs focus:ring-0"
                >
                    <span class="mr-2 inline-block h-2 w-2 shrink-0 rounded-full" :class="[getColorByStatus(userCallAvailability.slug)]" aria-hidden="true" />
                    <span class="hidden sm:inline">{{ dbTranslate(userCallAvailability.name) }}</span>
                    <ChevronDownIcon class="h-5 w-5 text-zinc-400" />
                </div>
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems class="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-hidden">
                <MenuItem v-for="item in callAvailabilities" v-slot="{ active }">
                    <div
                        :class="[active ? 'bg-gray-100' : '', 'block cursor-pointer px-4 py-2 text-sm text-gray-700']"
                        @click="changeCallAvailability(item.slug)"
                    >
                        <span class="mr-2 inline-block h-2 w-2 shrink-0 rounded-full" :class="[getColorByStatus(item.slug)]" aria-hidden="true" />
                        {{ dbTranslate(item.name) }}
                    </div>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>
