<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import { computed } from 'vue'

const { mode = 'save', disabled = false } = defineProps<{
    mode?: 'create' | 'save'
    disabled?: boolean
}>()

const label = computed(() => (mode === 'save' ? 'buttons.save' : 'actions.create'))
</script>

<template>
    <Button :disabled type="submit">
        <slot>{{ $t(label) }}</slot>
    </Button>
</template>
