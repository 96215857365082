<script setup lang="ts">
import Alert from '@app/components/ui/alert/Alert.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import DateInput from '@app/components/ui/form/DateInput.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Input from '@app/components/ui/form/Input.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import { useLocationSearch } from '@app/composables/useLocationSearch'
import type { Product } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm } from '@inertiajs/vue3'
import debounce from 'lodash/debounce'
import { inject, onMounted, watch, watchEffect, type Ref } from 'vue'

defineProps<{
    products: Product[]
    orderChannels: string[]
    leadProjects: string[]
    preferredCallTime: string[]
    kpnPromotionsLeadId: number
}>()

const sideBarOpen = inject('sideBarOpen') as Ref<boolean>

onMounted(() => (sideBarOpen.value = false))

const form = useForm({
    initials: '',
    last_name: '',
    postal_code: '',
    house_number: '',
    house_number_suffix: '',
    phone_number: '',
    street: '',
    place_of_residence: '',
    email: '',
    product_id: null,
    current_provider: '',
    contract_end_date: '',
    preferred_call_time: '',
    order_channel: '',
    extra_information: '',
})

const { search, street, placeOfResidence, reset, notFound } = useLocationSearch()

const debouncedSearch = debounce(search, 300)

watch([street, placeOfResidence], () => {
    form.street = street.value || ''
    form.place_of_residence = placeOfResidence.value || ''
})

watchEffect(() => {
    if (form.postal_code.length === 6 && !!form.house_number) {
        debouncedSearch(form.postal_code, form.house_number)
    } else {
        reset()
    }
})

function submit() {
    form.post('', {
        onSuccess() {
            form.reset()
        },
    })
}
</script>

<template>
    <div class="max-w-full">
        <img class="mx-auto pb-2" src="/assets/img/orders/logo-kpn.svg" width="250" height="auto" />
    </div>
    <Form class="mx-auto max-w-3xl" :form @submit="submit">
        <FormSection :title="$t('orders.forms.kpn_promotions.sections.client_details')">
            <FormField class="col-span-12 md:col-span-6" prop="initials" :label="$t('attributes.initials')">
                <Input v-model="form.initials" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="last_name" :label="$t('attributes.last_name')">
                <Input v-model="form.last_name" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="email" :label="$t('attributes.email')">
                <Input v-model="form.email" type="email" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="phone_number" :label="$t('attributes.phone_number')">
                <Input v-model="form.phone_number" />
            </FormField>

            <FormField class="col-span-12 md:col-span-5" prop="postal_code" :label="$t('attributes.postal_code')">
                <Input v-model="form.postal_code" />
            </FormField>

            <FormField class="col-span-6 md:col-span-5" prop="house_number" :label="$t('attributes.house_number')">
                <Input v-model="form.house_number" />
            </FormField>

            <FormField class="col-span-6 md:col-span-2" prop="house_number_suffix" :label="$t('attributes.house_number_suffix')">
                <Input v-model="form.house_number_suffix" />
            </FormField>

            <Alert v-if="form.street && form.place_of_residence" class="col-span-12">
                {{
                    $t('location.address_found', {
                        street: form.street,
                        house_number: form.house_number,
                        place_of_residence: form.place_of_residence,
                    })
                }}
            </Alert>

            <Alert v-else-if="notFound" type="error" class="col-span-12">
                {{ $t('location.address_not_found') }}
            </Alert>
        </FormSection>

        <FormSection :title="$t('orders.forms.kpn_promotions.sections.order_details')">
            <FormField class="col-span-12 md:col-span-6" prop="current_provider" :label="$t('orders.properties.current_provider')">
                <Input v-model="form.current_provider" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="contract_end_date" :label="$t('orders.properties.contract_end_date')">
                <DateInput v-model="form.contract_end_date" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="product_id" :label="$t('attributes.product')">
                <Select v-model="form.product_id" :placeholder="translatedSelectPlaceholder('attributes.product')">
                    <SelectOption v-for="product in products" :label="product.name" :value="product.id" :key="product.id" />
                </Select>
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="preferred_call_time" :label="$t('orders.properties.preferred_call_time')">
                <Select
                    v-model="form.preferred_call_time"
                    :placeholder="translatedSelectPlaceholder('orders.properties.preferred_call_time')"
                    :disabled="form.product_id !== kpnPromotionsLeadId"
                >
                    <SelectOption v-for="value in preferredCallTime" :label="$t(`orders.property_values.preferred_call_time.${value}`)" :value :key="value" />
                </Select>
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="order_channel" :label="$t('orders.properties.order_channel')">
                <Select v-model="form.order_channel" :placeholder="translatedSelectPlaceholder('orders.properties.order_channel')">
                    <SelectOption
                        v-for="orderChannel in orderChannels"
                        :label="$t(`orders.property_values.order_channel.${orderChannel}`)"
                        :value="orderChannel"
                        :key="orderChannel"
                    />
                </Select>
            </FormField>

            <FormField class="col-span-12" prop="extra_information" :label="$t('orders.properties.extra_information')">
                <FormTextArea v-model="form.extra_information" />
            </FormField>
        </FormSection>

        <FormActions>
            <SaveButton />
        </FormActions>
    </Form>
</template>
