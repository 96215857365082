<script setup lang="ts">
import Checkbox from '@app/components/orders/orderForms/wnl/Checkbox.vue'
import FormLabel from '@app/components/orders/orderForms/wnl/FormLabel.vue'
import PrimaryButton from '@app/components/orders/orderForms/wnl/PrimaryButton.vue'
import SecondaryButton from '@app/components/orders/orderForms/wnl/SecondaryButton.vue'
import TextField from '@app/components/orders/orderForms/wnl/TextField.vue'
import WnlOrderForm from '@app/components/orders/orderForms/wnl/WnlOrderForm.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import { useOrderForm } from '@app/composables/orders/useOrderForm'
import Wnl from '@app/layouts/Wnl.vue'
import { useForm } from '@inertiajs/vue3'

defineOptions({
    layout: Wnl,
})

type State = {
    first_name: string
    last_name: string
    email: string
    subscribe_newsletter: boolean
}

const { state, steps } = defineProps<{ steps: FormStep[]; state: Partial<State> }>()

const { currentStep } = useFormWizard(steps)

const { resetForm } = useOrderForm('wnl', steps)

const form = useForm({
    first_name: state.first_name ?? '',
    last_name: state.last_name ?? '',
    email: state.email ?? '',
    subscribe_newsletter: state.subscribe_newsletter ?? false,
    payment_agreement: currentStep.value?.isPassed ?? false,
    privacy_agreement: currentStep.value?.isPassed ?? false,
})
</script>

<template>
    <WnlOrderForm :steps title="Uw gegevens" description="Om uw aanmelding te voltooien, hebben wij enkele gegevens van u nodig.">
        <Form class="z-10 flex grow flex-col gap-2" :form @submit="form.post('')">
            <div>
                <FormLabel for="first_name">Wat is uw voornaam?</FormLabel>
                <TextField v-model="form.first_name" name="first_name" placeholder="Typ hier uw voornaam" :error="form.errors.first_name" autocomplete="off" />
                <FieldError class="mt-2" :message="form.errors.first_name" />
            </div>

            <div>
                <FormLabel for="last_name">Wat is uw achternaam?</FormLabel>
                <TextField v-model="form.last_name" name="last_name" placeholder="Typ hier uw achternaam" :error="form.errors.last_name" autocomplete="off" />
                <FieldError class="mt-2" :message="form.errors.last_name" />
            </div>

            <div>
                <FormLabel for="email">Wat is uw e-mailadres?</FormLabel>
                <TextField v-model="form.email" type="email" name="email" placeholder="Typ hier uw e-mailadres" :error="form.errors.email" autocomplete="off" />
                <FieldError class="mt-2" :message="form.errors.email" />
            </div>

            <div>
                <Checkbox name="subscribe_newsletter" v-model="form.subscribe_newsletter">Als lid ontvang ik graag de gratis WNL Vandaag nieuwsbrief.</Checkbox>
            </div>

            <div>
                <Checkbox name="payment_agreement" v-model="form.payment_agreement"
                    >Ik geef Omroep WNL toestemming om, tot wederopzegging, na mijn betaling jaarlijks €8,50 van mijn rekening af te schrijven. Eventuele extra
                    donaties worden eveneens jaarlijks via automatische incasso afgeschreven.</Checkbox
                >
            </div>

            <div>
                <Checkbox name="privacy_agreement" v-model="form.privacy_agreement">
                    Door het afronden van het lidmaatschap ga ik akkoord met de
                    <a class="underline hover:no-underline" href="https://wnl.tv/over-wnl/algemene-voorwaarden" target="_blank">algemene voorwaarden</a> en de
                    <a class="underline hover:no-underline" href="https://wnl.tv/over-wnl/privacy-statement" target="_blank">privacyverklaring</a>.
                </Checkbox>
            </div>

            <div class="mt-auto flex items-center justify-between">
                <PrimaryButton class="min-w-52" :disabled="form.processing || !form.payment_agreement || !form.privacy_agreement">Verder</PrimaryButton>
                <SecondaryButton type="button" @click="resetForm">Reset</SecondaryButton>
            </div>
        </Form>
    </WnlOrderForm>
</template>
