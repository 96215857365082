<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import CreateAvailabilityForm from '@app/components/shifts/availabilities/forms/CreateAvailabilityForm.vue'
import PlanningShiftAvailabilityEvent from '@app/components/shifts/shifts/planning/PlanningShiftAvailabilityEvent.vue'
import PlanShiftForm from '@app/components/shifts/shifts/planning/PlanShiftForm.vue'
import RefreshBriggsWalkerShiftsButton from '@app/components/shifts/shifts/RefreshBriggsWalkerShiftsButton.vue'
import ShiftCard from '@app/components/shifts/shifts/ShiftCard.vue'
import ShiftMemberCard from '@app/components/shifts/shifts/ShiftMemberCard.vue'
import ToggleButton from '@app/components/ui/button/ToggleButton.vue'
import TimeGridHeader from '@app/components/ui/calendar/body/TimeGridHeader.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import DropdownFilter from '@app/components/ui/table/filters/DropdownFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import UserNameAndAvatar from '@app/components/users/UserNameAndAvatar.vue'
import { useWeekSwitcher } from '@app/composables/calendar/useWeekSwitcher'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useShiftAvailabilityEvent } from '@app/composables/shifts/useShiftAvailabilityEvent'
import { provideShiftPlanning } from '@app/composables/shifts/useShiftsPlanning'
import { useChannel } from '@app/composables/useEcho'
import { useUserCan } from '@app/composables/useUserCan'
import type { Branch, ClientProject, LocationType, User, WorkType } from '@app/types/shared'
import {
    type Shift,
    type ShiftAvailability,
    type ShiftAvailabilityEvent as ShiftAvailabilityEventType,
    type ShiftMember,
    type ShiftPlanningMode,
    type ShiftTemplate,
} from '@app/types/shifts'
import { reloadPartial } from '@app/utils/inertia'
import { OpeningHours, type OpeningHoursState } from '@app/utils/openingHours'
import { eachDayOfInterval, endOfWeek, isPast, startOfWeek } from 'date-fns'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'

const { shiftPlanningTabs } = useNavigation()
const { can } = useUserCan()
const { subscribe } = useChannel('shift')

const { branches, locationTypes, users, clientProjects, selectedShift, selectedAvailability, shiftOpeningHours, selectedWorkTypeSlug } = defineProps<{
    users: User[]
    branches: Branch[]
    locationTypes: LocationType[]
    clientProjects: ClientProject[]
    workTypes: WorkType[]
    userShiftTemplates: ShiftTemplate[]
    selectedShift: Shift | null
    selectedAvailability: ShiftAvailability | null
    selectedShiftMember: ShiftMember | null
    shiftOpeningHours: OpeningHoursState | null
    selectedWorkTypeSlug: string
}>()

const emit = defineEmits(['create-shift:close', 'close'])

const openingHours = computed(() => {
    if (shiftOpeningHours) {
        return new OpeningHours(shiftOpeningHours)
    }

    return null
})

const { selectedDay, switchWeek } = useWeekSwitcher({ weekParamName: 'week', reloadOnly: ['users'] })

const sortedDay = ref<Date>()

const formShown = ref<'shift' | 'availability'>()

const selectedAvailabilities = ref<ShiftAvailabilityEventType[]>([])

const weekdays = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

subscribe('.shift.members.marked-as-should-have-started', () => {
    reloadPartial(['users'])
})

const { shiftPlanningForm, reset, isDisabled } = provideShiftPlanning()
const { closeCards } = useShiftAvailabilityEvent()

const supportsTeamShifts = computed(() => selectedWorkTypeSlug !== 'contact-center')

function sortUsersByName() {
    if (!sortedDay.value) {
        return
    }

    sortedDay.value = undefined

    users.sort((a, b) => {
        return a.full_name === b.full_name ? 0 : a.full_name > b.full_name ? 1 : -1
    })
}

function sortUsersByDay(day: Date) {
    sortedDay.value = day
    users.sort((a, b) => {
        const hasAvailabilityA = a.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())
        const hasAvailabilityB = b.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

        return hasAvailabilityA === hasAvailabilityB ? 0 : hasAvailabilityA ? -1 : 1
    })
}

const shiftPlanningMode = computed<ShiftPlanningMode>(() => {
    if (!isPlanningEnabled.value) {
        return 'view'
    }

    return supportsTeamShifts.value ? 'team' : 'individual'
})

const selectedDate = ref(new Date())
const selectedUser = ref()

const handleClick = (user: User, day: Date) => {
    if (formShown.value === 'shift') {
        return
    }

    const hasNoAvailability = !user.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

    if (hasNoAvailability && !isPast(day.setHours(23, 59, 59))) {
        formShown.value = 'availability'
        selectedDate.value = day
        selectedUser.value = user
    }
}

function selectAllAvailability() {
    shiftPlanningForm.shifts = users
        .flatMap((user) => user.shift_availabilities.filter((availability) => !isDisabled(availability)))
        .map((availability) => ({ availabilities: [availability], note: '', location_type_id: availability.location_type_id }))
}

function userAvailabilitiesForDay(user: User, day: Date) {
    return user.shift_availabilities.filter((item) => {
        return new Date(item.from).toDateString() === day.toDateString()
    })
}

watch(formShown, (newValue) => {
    if (newValue) {
        // Clear selected availability, shift and shift_member when a form is shown
        closeCards()
    } else {
        reset()
    }
})

const isPlanningEnabled = ref(false)

watch(isPlanningEnabled, (newValue) => {
    formShown.value = newValue ? 'shift' : undefined
})

function closeForm() {
    isPlanningEnabled.value = false
    selectedAvailabilities.value = []
    formShown.value = undefined
}

const types = computed(() => [
    {
        name: 'attributes.availability',
        slug: 'availability',
    },
    {
        name: 'attributes.shift',
        slug: 'shift',
    },
])
</script>

<template>
    <div>
        <ContentTabs bottomBorder :items="shiftPlanningTabs" />

        <div class="min-h-[24px]">
            <div class="scrollbar-hide flex items-start gap-4">
                <div class="flex h-full w-full flex-col">
                    <Header :date="selectedDay" class="items-end!">
                        <template #filters>
                            <div class="flex flex-wrap items-end gap-4">
                                <FilterSection :showPerPageOptions="false" class="-mb-2 -ml-4 px-0">
                                    <DropdownFilter
                                        border
                                        :items="types"
                                        :label="$t('attributes.type')"
                                        slug="type"
                                        property-name="name"
                                        :translate-db="false"
                                        by="slug"
                                    />
                                    <DropdownMultiFilter border :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />
                                    <DropdownMultiFilter
                                        v-if="route().params.workType !== 'contact-center'"
                                        border
                                        :items="locationTypes"
                                        :label="$t('attributes.location_type')"
                                        slug="location_type"
                                    />
                                    <DropdownMultiFilter border :items="branches" :label="$t('attributes.branch')" slug="branch" />
                                    <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" showLabel />
                                    <ResetFilterButton
                                        :href="route('shifts.planning.index', { workType: route().params.workType, week: route().params.week })"
                                        :label="$t('buttons.clear')"
                                    />
                                </FilterSection>
                            </div>
                        </template>
                        <div class="flex items-center gap-2">
                            <template v-if="route().params.workType === 'sales-agent'">
                                <Divider class="my-2" />
                                <RefreshBriggsWalkerShiftsButton :only="['users']" />
                            </template>

                            <Divider v-if="can('shifts.shift.store')" class="" />
                            <div v-if="can('shifts.shift.store')" class="hidden items-center gap-2 text-sm font-medium text-zinc-800 md:flex">
                                <ToggleButton v-model="isPlanningEnabled" />Plan
                            </div>
                        </div>
                    </Header>
                    <TwoPaneLayout class="mt-2">
                        <div
                            ref="container"
                            class="transition-width scrollbar-hide isolate flex flex-auto flex-col overflow-y-hidden rounded-lg border-zinc-100 bg-white duration-100 ease-in-out sm:border"
                        >
                            <div class="flex h-full w-[300%] flex-none flex-col md:w-[200%] lg:w-full">
                                <div
                                    class="scrollbar-thin sticky top-0 z-50 flex-none overflow-y-auto shadow-xs ring-1 ring-black/5"
                                    :style="{ scrollbarGutter: 'stable' }"
                                >
                                    <div class="grid grid-cols-7 divide-x divide-zinc-100 border-b border-zinc-100 text-xs leading-4 text-zinc-500">
                                        <TimeGridHeader class="left-0 col-end-1 w-36 md:w-52" sortable :active="!sortedDay" @sort="sortUsersByName">
                                            {{ $t('attributes.name') }}
                                        </TimeGridHeader>
                                        <Weekdays :days="weekdays" :sortedDay="sortedDay" shorthand sortable @sort="sortUsersByDay" />
                                    </div>
                                </div>
                                <div
                                    class="scrollbar-thin scrollbar-track-gray-100 h-[calc(100vh-300px)] overflow-y-auto"
                                    :style="{ scrollbarGutter: 'stable' }"
                                >
                                    <div class="flex">
                                        <table class="min-w-full table-fixed border-t-0">
                                            <tbody class="divide-y divide-zinc-100 bg-white">
                                                <tr
                                                    v-for="user in users"
                                                    class="row-span-4 grid min-h-16 w-full grid-cols-7 flex-col justify-between divide-x divide-zinc-100 text-sm"
                                                >
                                                    <td
                                                        class="sticky left-0 z-40 col-span-1 col-end-1 flex w-36 items-center border-r border-zinc-100 bg-white px-4 whitespace-normal md:w-52"
                                                    >
                                                        <UserNameAndAvatar :user="user" />
                                                    </td>
                                                    <td
                                                        v-for="day in weekdays"
                                                        class="flex max-h-full flex-col items-center overflow-hidden"
                                                        :class="{ 'bg-gray-100': openingHours?.isClosedAt(day) }"
                                                    >
                                                        <div class="flex h-full w-full cursor-pointer flex-col gap-1" @click="handleClick(user, day)">
                                                            <PlanningShiftAvailabilityEvent
                                                                v-for="availability in userAvailabilitiesForDay(user, day)"
                                                                :availability
                                                                :mode="shiftPlanningMode"
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <template #detail v-if="formShown === 'shift'">
                            <PlanShiftForm :mode="shiftPlanningMode" @select:all="selectAllAvailability" @close="closeForm" />
                        </template>
                        <template #detail v-else-if="formShown === 'availability'">
                            <CreateAvailabilityForm @close="closeForm" :date="selectedDate" :plannerShiftTemplates="userShiftTemplates" :user="selectedUser" />
                        </template>
                        <template #detail v-else-if="selectedAvailability">
                            <AvailabilityCard @close="closeCards" :availability="selectedAvailability" />
                        </template>
                        <template #detail v-else-if="selectedShift">
                            <ShiftCard @close="closeCards" :shift="selectedShift" />
                        </template>
                        <template #detail v-else-if="selectedShiftMember">
                            <ShiftMemberCard :shift-member="selectedShiftMember" @close="closeCards()" />
                        </template>
                    </TwoPaneLayout>
                </div>
            </div>
        </div>
    </div>
</template>
