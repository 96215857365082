<script setup lang="ts">
import type { OngoingShift } from '@app/types/shifts'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import EndShiftButton from './EndShiftButton.vue'
import StartShiftButton from './StartShiftButton.vue'

const shiftsToday = computed(() => usePage().props.shiftsToday as OngoingShift[])

// For the FCC shifts milestone, we will only support single shifts.
// Later on, we will have to adjust this button to support multiple shifts on one day.
// Something like https://tailwindui.com/components/application-ui/elements/dropdowns#component-f8a14da22f26a67757b19f2fe3ca00ed should suffice.
const shiftToStart = computed(() => shiftsToday.value.find((shift) => ['planned', 'should-have-started'].includes(shift.status)))

const currentShift = computed(() => shiftsToday.value.find((shift) => shift.status === 'started'))
</script>

<template>
    <StartShiftButton v-if="shiftToStart" :shift="shiftToStart" />
    <EndShiftButton v-if="currentShift" :shift="currentShift" />
</template>
