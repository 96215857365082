<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import type { OngoingShift } from '@app/types/shifts'
import { StopCircleIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'
import EndShift from '../actions/EndShift.vue'

defineProps<{ shift: OngoingShift }>()

const formShown = ref(false)
</script>

<template>
    <Button color="red" @click="formShown = true">
        <StopCircleIcon class="size-5 text-white" />
        <div>{{ $t('buttons.end_shift') }}</div>
    </Button>

    <ModalLayout :show="formShown" @close="formShown = false">
        <EndShift :shift @close="formShown = false" />
    </ModalLayout>
</template>
