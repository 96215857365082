<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import DragAndDropArea from '@app/components/ui/dragAndDrop/DragAndDropArea.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import { useShiftPlanning } from '@app/composables/shifts/useShiftsPlanning'
import type { ShiftPlanningMode } from '@app/types/shifts'
import { nextTick, ref, useTemplateRef, watch } from 'vue'
import { route } from 'ziggy-js'
import PlanningShiftItem from './PlanningShiftItem.vue'

const { mode } = defineProps<{
    mode: ShiftPlanningMode
}>()

const { shiftPlanningForm, draggingAvailability, isSelected } = useShiftPlanning()

const isDroppingAllowed = ref(true)
const teamsList = useTemplateRef('teamsList')

watch(draggingAvailability, () => {
    isDroppingAllowed.value = !draggingAvailability.value || !isSelected(draggingAvailability.value)
})

const emit = defineEmits(['close', 'select:all'])

async function addShift(event: DragEvent) {
    if (!draggingAvailability.value) {
        return
    }

    event.preventDefault()

    shiftPlanningForm.shifts = [
        ...shiftPlanningForm.shifts,
        {
            availabilities: [draggingAvailability.value],
            note: '',
            location_type_id: draggingAvailability.value.location_type_id,
        },
    ]

    // scroll to the end of the list when adding a shift
    if (teamsList.value) {
        await nextTick()

        teamsList.value.scroll({
            top: teamsList.value.scrollHeight,
            behavior: 'smooth',
        })
    }
}

function planShifts() {
    shiftPlanningForm
        .transform((data) => ({
            shifts: data.shifts.map((shift) => ({
                ...shift,
                availabilities: shift.availabilities.map((item) => item.id),
            })),
        }))
        .post(route('shifts.planning.store'), {
            only: ['users'],
            onSuccess() {
                emit('close')
            },
        })
}

function removeShift(index: number) {
    shiftPlanningForm.shifts.splice(index, 1)
}
</script>

<template>
    <Card>
        <div class="flex h-full w-full flex-col gap-4">
            <template v-if="mode === 'individual'">
                <div v-if="shiftPlanningForm.shifts.length === 0" class="text-gray-500">{{ $t('shifts.plan_shifts_description') }}</div>

                <div class="flex flex-wrap gap-2">
                    <Button color="white" class="whitespace-normal!" size="xs" @click="$emit('select:all')">{{ $t('shifts.planning.select_all') }}</Button>
                    <Button v-if="shiftPlanningForm.shifts.length > 0" color="white" size="xs" @click="shiftPlanningForm.shifts = []">{{
                        $t('form.clear_selection')
                    }}</Button>
                </div>
            </template>

            <div ref="teamsList" class="scrollbar-thin max-h-[calc(100vh-545px)] divide-y divide-zinc-200 overflow-x-hidden overflow-y-auto">
                <PlanningShiftItem
                    v-for="(_, index) in shiftPlanningForm.shifts"
                    v-model="shiftPlanningForm.shifts[index]"
                    :multiple="mode === 'team'"
                    @shift:remove="removeShift(index)"
                />
            </div>

            <div v-if="mode === 'team'" class="mt-2 pt-4">
                <DragAndDropArea :allowed="isDroppingAllowed" @dragover.prevent @drop.prevent="addShift">
                    {{ $t('shifts.planning.dnd') }}
                </DragAndDropArea>
            </div>

            <FormActions class="mt-4">
                <SaveButton :disabled="shiftPlanningForm.shifts.length === 0 || shiftPlanningForm.processing" @click="planShifts" />
            </FormActions>
        </div>
    </Card>
</template>
