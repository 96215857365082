<script setup lang="ts">
import AmountButton from '@app/components/orders/orderForms/hartstichting/buttons/AmountButton.vue'
import CustomAmountButton from '@app/components/orders/orderForms/hartstichting/buttons/CustomAmountButton.vue'
import MoreInfoButton from '@app/components/orders/orderForms/hartstichting/buttons/MoreInfoButton.vue'
import TealButton from '@app/components/orders/orderForms/hartstichting/buttons/TealButton.vue'
import CheckboxInput from '@app/components/orders/orderForms/hartstichting/forms/CheckboxInput.vue'
import RadioInput from '@app/components/orders/orderForms/hartstichting/forms/RadioInput.vue'
import TextInput from '@app/components/orders/orderForms/hartstichting/forms/TextInput.vue'
import { inject, onMounted } from 'vue'

const sideBarOpen = inject('sideBarOpen')

onMounted(() => (sideBarOpen.value = false))
</script>

<template>
    <div class="max-w-full">
        <img class="mx-auto pb-2" src="/assets/img/orders/logo-hartstichting.png" width="250" height="auto" />
    </div>
    <div class="items-top relative flex min-h-screen flex-wrap justify-center leading-relaxed sm:items-center">
        <div class="max-w-(--breakpoint-lg) mx-auto">
            <div class="bg-slate-100">
                <div class="px-6 py-8 lg:px-8">
                    <h1 class="pb-8 text-center text-4xl tracking-wider text-rose-600">Ja, ik geef voor een beter leven van hartpatiënten.</h1>
                    <div class="px-4 sm:px-10 lg:px-20">
                        <p class="px-2 pb-2 text-lg tracking-wider">Ik machtig daarom de hartstichting om per maand een bedrag af te schrijven van</p>
                        <div class="grid grid-cols-2 gap-10 pb-6 md:grid-cols-4">
                            <AmountButton :amount="10" />
                            <AmountButton :amount="12.5" />
                            <AmountButton :amount="15" />
                            <CustomAmountButton />
                        </div>
                        <div class="pb-6">
                            <p class="px-2 pb-2">Gewenste aanhef</p>
                            <RadioInput name="gender" value="male" title="Meneer" />
                            <RadioInput name="gender" value="female" title="Mevrouw" />
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <TextInput title="Voornaam" name="firstname" :colsize="3" />
                            <TextInput title="Tussenvoegsel" name="tussenvoegsel" :colsize="2" :required="false" />
                            <TextInput title="Achternaam" name="lastname" :colsize="5" />
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <TextInput title="Geboortedatum" name="birthday" type="date" :colsize="3" />
                            <div class="lg:col-span-2">
                                <p class="md:pt-8">Leeftijd: 0</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <TextInput title="Straatnaam" name="street" :colsize="3" />
                            <TextInput title="Huisnummer" name="housenumber" :colsize="2" />
                            <TextInput title="Toevoeging" name="houseadd" :colsize="5" :required="false" />
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <TextInput title="Postcode" name="zip" :colsize="3" />
                            <TextInput title="Woonplaats" name="woonplaats" :colsize="7" />
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <div class="lg:col-span-3">
                                <p class="px-2">Bank *</p>
                                <select class="focus:outline-hidden w-full rounded-lg border border-slate-300 p-2 focus:ring-1 focus:ring-teal-500" name="bank">
                                    <option value="ing">ING</option>
                                    <option value="sns">SNS</option>
                                </select>
                            </div>
                            <TextInput title="IBAN" name="iban" :colsize="7" />
                            <div class="lg:col-span-10">
                                <p class="px-2">Bankrekening wordt automatisch omgezet naar IBAN en er wordt gecheckt of het rekeningnummer bestaat.</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-1 gap-5 pb-6 md:grid-cols-2 lg:grid-cols-10">
                            <TextInput title="Telefoonnummer" name="phone" type="tel" :colsize="3" />
                            <TextInput title="Emailadres" name="email" :colsize="7" />
                        </div>
                        <div class="focus:outline-hidden rounded-lg border border-slate-300 bg-white px-6 py-3 focus:ring-1 focus:ring-teal-500">
                            <p>Het is mij bekend dat ...</p>

                            <CheckboxInput name="check_1" value="check_1" text="... dit GEEN eenmalige machtiging betreft." />
                            <br />

                            <CheckboxInput name="check_2" value="check_2" text="... ik de Hartstichting tot wederopzegging steun." />
                            <br />

                            <CheckboxInput
                                name="check_3"
                                value="check_3"
                                text="De Hartstichting mag me telefonisch benaderen om me op de hoogte te houden van projecten en ontwikkelingen binnen de Hartstichting en mijn donateurschap."
                                v-bind:checked="true"
                            />
                            <MoreInfoButton>
                                De Hartstichting gebruikt uw telefoonnummer om u te benaderen over projecten en ontwikkelingen binnen de Hartstichting en uw
                                donateurschap. U kan altijd op een later moment aangeven niet meer benaderd te willen worden. Hiervoor kan worden gebeld met
                                0800-821 2480. Indien u dit niet wilt, kunt u dat hier aangeven.
                            </MoreInfoButton>
                            <CheckboxInput
                                name="check_4"
                                value="check_4"
                                text="Ja, de hartstichting mag me per e-mail benaderen om me op de hoogte te houden van projecten en ontwikkelingen binnen de Hartstichting en het donateurschap."
                                v-bind:checked="true"
                            />
                            <MoreInfoButton>
                                De Hartstichting gebruikt uw e-mailadres om u te benaderen over projecten en ontwikkelingen binnen de Hartstichting en uw
                                donateurschap. Indien u dit niet wilt, kunt u dat hier aangeven. U kunt zich ook altijd op een later moment afmelden via de
                                afmeldlink onderaan de e-mails.
                            </MoreInfoButton>
                        </div>
                        <div class="mx-auto max-w-xl py-6">
                            <div class="pb-6">
                                <p class="px-2 pb-2">Verificatiemethode</p>
                                <RadioInput name="verification" value="sms" title="SMS" />
                                <RadioInput name="verification" value="sign" title="Handtekening" />
                            </div>
                            <div class="grid grid-cols-2 gap-5 pb-6 lg:grid-cols-10">
                                <TealButton text="Verstuur SMS" />
                                <TextInput title="Verificatiecode" name="code" :colsize="5" />
                            </div>
                            <div>
                                <button class="w-full rounded-lg bg-rose-600 py-2 text-lg font-semibold text-white">Bevestig donatie</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
