<script setup lang="ts">
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { computed } from 'vue'

const { steps } = defineProps<{ steps: FormStep[] }>()

const visibleSteps = computed(() => steps.filter((step) => !step.isHidden))
</script>

<template>
    <section
        v-if="steps.length"
        class="steps relative grid h-16 w-full grid-flow-col grid-rows-[unset] content-center justify-start gap-4 lg:h-[calc(100%-10px)] lg:grid-rows-[1fr_1fr_4fr_4fr]"
    >
        <span
            v-for="(step, index) in visibleSteps"
            class="step relative size-[var(--step-indicator-size)] self-end rounded-full text-center text-sm leading-[var(--step-indicator-size)] font-semibold first-of-type:self-start"
            :class="[step.isActive || step.isPassed ? 'bg-wnl-orange text-white' : 'text-wnl-orange bg-white']"
        >
            {{ index + 1 }}
        </span>
    </section>
</template>

<style>
:root {
    --step-indicator-size: 26px;
}

.step {
    box-shadow: 0 0 0 2px var(--color-wnl-orange);
}

@media (width >= 64rem) {
    .steps::before {
        content: '';
        position: absolute;
        top: 0;
        left: calc(var(--step-indicator-size) / 2);
        width: 2px;
        height: 100%;
        background-color: var(--color-wnl-orange);
    }

    .step {
        box-shadow:
            0 0 0 2px var(--color-wnl-orange),
            0 0 0 15px var(--color-white);
    }
}
</style>
