<script setup lang="ts">
import Filters from '@app/components/recruitment/incoming/Filters.vue'
import AssignToMe from '@app/components/tasks/actions/AssignToMe.vue'
import CompleteTask from '@app/components/tasks/actions/CompleteTask.vue'
import Link from '@app/components/ui/table/column/Link.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useChannel } from '@app/composables/useEcho'
import type { Call } from '@app/types/calls'
import type { ResourceCollection, User } from '@app/types/shared'
import type { Taskable } from '@app/types/task'
import { reloadPartial } from '@app/utils/inertia'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChatBubbleLeftRightIcon, EllipsisHorizontalIcon, PhoneIcon } from '@heroicons/vue/20/solid'
import { computed, inject, onMounted } from 'vue'
import { route } from 'ziggy-js'

type TaskItem = Taskable<any>

defineProps<{
    tasks: ResourceCollection<TaskItem>
    user: User
    users: any
}>()

const { startOutgoingCall, callInProgress } = inject<any>('twilio')
const { getRedirectToLeadHref } = useNavigation()

const columns = computed(() => [
    { name: 'recipient', header: 'lead' },
    { name: 'type', header: '' },
    { type: 'multi-line-date', header: 'recruitment.leads.index.created_at', properties: { name: 'created_at' } },
    { name: 'user', header: 'user' },
    { name: 'actions', header: 'recruitment.incoming.actions' },
])

const { subscribe } = useChannel('recruitment')

onMounted(() => {
    // listen for unknown calls and whatsapp messages and reload task list
    subscribe('.call.completed', (e: any) => {
        const isIncoming = e.direction === 'incoming'
        const isRejectedOrMissed = ['rejected', 'missed'].includes(e.status)

        if (isIncoming && isRejectedOrMissed) {
            reloadPartial(['tasks'])
        }
    })
    subscribe('.whatsapp.message.created', (e: any) => {
        if (!e.lead_id) {
            reloadPartial(['tasks'])
        }
    })
    subscribe('.recruitment.lead.created', () => reloadPartial(['tasks']))
})

function handleCallClick(item: TaskItem) {
    if (item.taskable.lead) {
        startOutgoingCall(item.taskable.lead)
    } else {
        startOutgoingCall({
            phone_number: item.taskable.from,
        })
    }
}

function isCall(taskable: any): taskable is Call {
    return !('message' in taskable)
}
</script>

<template>
    <div class="scrollbar-hide z-0 flex items-start gap-4 overflow-visible">
        <DataTable :items="tasks" :columns="columns" only="tasks" class="-p-4 z-0 overflow-visible">
            <template #filter>
                <Filters class="transition-all duration-500" :users="users" />
            </template>

            <template #column.recipient="{ item }">
                <Link v-if="item.taskable.lead" :link="{ label: item.taskable.lead.full_name, href: getRedirectToLeadHref(item.taskable.lead) }" />
                <span v-else>{{ item.taskable.phone_number || item.taskable.from }}</span>
            </template>

            <template #column.type="{ item }">
                <div v-if="isCall(item.taskable)" class="flex items-center gap-4">
                    <div class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100">
                        <PhoneIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                    {{ $t(`call.status.${item.taskable.status}`) }}
                </div>

                <div v-else class="flex max-w-sm items-start gap-4 whitespace-normal">
                    <div class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gray-100">
                        <ChatBubbleLeftRightIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                    <p class="mt-2 text-sm">{{ item.taskable.message }}</p>
                </div>
            </template>

            <template #column.user="{ item }">
                <span :class="{ 'text-gray-400': !item.user }">
                    {{ item.user?.full_name || $t('tasks.unassigned') }}
                </span>
            </template>

            <template #column.actions="{ item }">
                <div class="flex gap-4">
                    <button @click="handleCallClick(item)" :disabled="callInProgress" :title="$t('recruitment.incoming.call_back')">
                        <PhoneIcon class="text-primary-600 h-5 w-5 disabled:text-gray-400" aria-hidden="true" />
                    </button>

                    <div>
                        <Menu as="div" class="relative">
                            <MenuButton class="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                                <span class="sr-only">Open options</span>
                                <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
                            </MenuButton>

                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden"
                                >
                                    <MenuItem>
                                        <CompleteTask :location="route('task.complete', item.id)" :taskable="item" />
                                    </MenuItem>

                                    <MenuItem>
                                        <AssignToMe :location="route('task.assign', item.id)" :user-id="user.id" :taskable="item" />
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>
