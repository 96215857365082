<script setup lang="ts">
import { differenceInMinutes, format } from 'date-fns'
import { computed, inject, type ComputedRef } from 'vue'

const { from, to } = defineProps<{ from: string; to: string }>()

const dayNumber = computed(() => format(new Date(from), 'i'))

const startingHours = inject('calendar:time:start') as ComputedRef<number>

const rowNumber = computed(() => {
    const initialRowOffset = 2
    const date = new Date(from)

    const rowsFromHours = ((date.getHours() - startingHours.value) % 12) * 4
    const rowsFromMinutes = Math.round(date.getMinutes() / 15)

    return initialRowOffset + rowsFromHours + rowsFromMinutes
})

const rowSpan = computed(() => {
    const dateFrom = new Date(from)
    const dateTo = new Date(to)

    const durationInMinutes = Math.abs(differenceInMinutes(dateFrom, dateTo))

    return Math.max(Math.round(durationInMinutes / 15), 4)
})

const gridRow = computed(() => `${rowNumber.value} / span ${rowSpan.value}`)
</script>

<template>
    <div class="relative flex" :style="{ 'grid-row': gridRow, 'grid-column-start': dayNumber }">
        <slot />
    </div>
</template>
