<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMemberIssue } from '@app/types/shifts'

defineProps<{ issue: ShiftMemberIssue }>()

const { translate } = useLocale()
</script>

<template>
    <div>
        <div class="relative px-1">
            <div class="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white" :class="issue.resolved_at ? 'bg-green-100' : 'bg-zinc-100'">
                <!-- <CheckCircleIcon v-if="issue.resolved_at" class="h-5 w-5 text-green-500" /> -->
                <slot name="icon" />
            </div>
        </div>
    </div>
    <div class="min-w-0 flex-1">
        <div class="text-sm leading-8 text-zinc-800">{{ translate(issue.issue_type.name) }}</div>
        <div class="text-sm text-zinc-500">
            <p>
                <slot></slot>
            </p>
        </div>
    </div>
</template>
