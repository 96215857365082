<script setup>
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useLocale } from '@app/composables/useLocale'
import { computed } from 'vue'

const { sideBarNavigation } = useNavigation()
const { translate } = useLocale()

const activeNavigation = computed(() => sideBarNavigation.value.find((navigation) => navigation.active))

const pageTitle = computed(() =>
    activeNavigation.value.items ? activeNavigation.value.items.find((subnavigation) => subnavigation.active)?.name : activeNavigation.value.name
)
const module = computed(() => (activeNavigation.value.items ? activeNavigation.value.name : null))
</script>

<template>
    <div class="relative hidden border-zinc-200 xl:block">
        <div class="md:flex md:items-center md:justify-between">
            <div v-if="pageTitle" class="flex flex-wrap items-baseline">
                <div class="text-2xl leading-7 font-semibold text-zinc-900">
                    {{ translate(pageTitle) }}
                </div>
                <p v-if="module" class="mt-1 ml-2 truncate text-base text-zinc-500">in {{ translate(module) }}</p>
            </div>
        </div>
    </div>
</template>
