import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

export function useOrderForm(project: string, steps: FormStep[]) {
    function closeForm() {
        router.post(route('orders.form.close', { project }), {
            redirectUrl: '',
        })
    }

    function resetForm() {
        router.post(route('orders.form.close', { project }), {
            redirectUrl: steps.find((step) => step.isEnabled)?.url,
        })
    }

    return {
        closeForm,
        resetForm,
    }
}
