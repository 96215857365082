<script setup lang="ts">
import type { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

const buttonColors = {
    primary: 'text-white bg-primary-600 hover:enabled:bg-primary-700 focus:ring-primary-600 disabled:bg-primary-400',
    secondary: 'text-white bg-fonky-black hover:bg-zinc-700 focus:ring-primary-600',
    ghost: 'text-zinc-600 underline hover:text-zinc-900',
    white: 'text-zinc-700 hover:enabled:bg-zinc-50 focus:ring-primary-600 border border-zinc-300 bg-white disabled:bg-zinc-100 disabled:text-zinc-400',
    red: 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500',
    green: 'text-white bg-green-600 hover:bg-green-700 focus:ring-green-500',
    black: 'bg-zinc-800 border border-transparent text-white tracking-widest hover:bg-zinc-700 font-semibold uppercase tracking-widest active:bg-zinc-900 focus:outline-hidden focus:border-gray-900 focus:ring-3 focus:ring-gray-300 disabled:opacity-25',
} as const

type ButtonColor = keyof typeof buttonColors

const buttonSizes = {
    xs: 'text-xs leading-4 px-2.5 py-1.5 gap-2',
    sm: 'text-sm leading-4 px-3 py-2 gap-2',
    base: 'text-sm leading-5 px-4 py-2 gap-2',
    lg: 'text-base leading-6 px-4 py-2 gap-3',
    xl: 'text-base leading-6 px-6 py-3 gap-3',
} as const

type ButtonSize = keyof typeof buttonSizes

const props = withDefaults(
    defineProps<{
        as?: 'button' | 'a' | typeof Link
        type?: 'submit' | 'button'
        color?: ButtonColor
        size?: ButtonSize
        href?: string
        target?: '_self' | '_blank'
    }>(),
    {
        as: 'button',
        type: 'submit',
        color: 'primary',
        size: 'base',
        target: '_self',
    }
)

if (props.as !== 'button' && !props.href) {
    throw new Error('Link should have a href property')
}

const selectedType = computed(() => buttonColors[props.color])
const selectedSize = computed(() => buttonSizes[props.size])
</script>

<template>
    <component
        :is="as"
        :href="href"
        :type="type"
        class="focus:outline-hidden flex items-center justify-around whitespace-nowrap rounded-md text-center font-medium transition-all duration-200 focus:ring-2 focus:ring-offset-2"
        :class="[selectedType, selectedSize, color !== 'ghost' ? 'shadow-xs' : '']"
        :target="target"
    >
        <slot></slot>
    </component>
</template>
