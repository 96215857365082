<script setup lang="ts">
import { computed, ref } from 'vue'

const layoutFractions = ['2/3', '3/4'] as const

const { fraction = '2/3' } = defineProps<{ fraction?: (typeof layoutFractions)[number] }>()

const tableTransitioningClass = computed(() => {
    if (fraction === '3/4') {
        return 'hidden lg:block lg:w-3/4'
    }
    if (fraction === '2/3') {
        return 'hidden lg:block lg:w-2/3'
    }
})

const transitionClass = computed(() => {
    if (fraction === '3/4') {
        return 'lg:w-1/4'
    }
    if (fraction === '2/3') {
        return 'lg:w-1/3 '
    }
})

const isTransitioning = ref(false)

function onBeforeLeave() {
    isTransitioning.value = true
}

function onAfterLeave() {
    isTransitioning.value = false
}
</script>

<template>
    <div class="scrollbar-hide flex w-full items-stretch gap-2 lg:gap-4">
        <div class="table-transition" :class="isTransitioning || $slots.detail ? tableTransitioningClass : 'block w-full'">
            <slot />
        </div>

        <Transition name="slide-fade" mode="out-in" @before-leave="onBeforeLeave" @after-leave="onAfterLeave">
            <div v-if="$slots.detail" class="w-full md:min-w-[29rem]" :class="transitionClass">
                <slot name="detail" />
            </div>
        </Transition>
    </div>
</template>
