<script setup lang="ts">
import PrimaryButton from '@app/components/orders/orderForms/wnl/PrimaryButton.vue'
import SecondaryButton from '@app/components/orders/orderForms/wnl/SecondaryButton.vue'
import WnlOrderForm from '@app/components/orders/orderForms/wnl/WnlOrderForm.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useOrderForm } from '@app/composables/orders/useOrderForm'
import Wnl from '@app/layouts/Wnl.vue'
import type { Bank } from '@app/types/shared'

defineOptions({
    layout: Wnl,
})

const { steps } = defineProps<{ steps: FormStep[]; banks: Bank[] }>()

const { closeForm, resetForm } = useOrderForm('wnl', steps)
</script>

<template>
    <WnlOrderForm :steps title="Dank u wel dat u lid bent geworden van WNL!">
        <div class="mt-4 flex items-center gap-4">
            <PrimaryButton type="button" @click="resetForm">+ Nieuw lid</PrimaryButton>

            <SecondaryButton type="button" @click="closeForm">Terug naar MF2</SecondaryButton>
        </div>
    </WnlOrderForm>
</template>
