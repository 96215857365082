<script setup lang="ts">
import { useShiftAvailabilityEvent } from '@app/composables/shifts/useShiftAvailabilityEvent'
import { useShiftPlanning } from '@app/composables/shifts/useShiftsPlanning'
import type { ShiftAvailabilityEvent as ShiftAvailabilityEventType, ShiftPlanningMode } from '@app/types/shifts'
import { computed, ref } from 'vue'
import ShiftAvailabilityEvent from '../../availabilities/ShiftAvailabilityEvent.vue'

const { availability, mode } = defineProps<{ availability: ShiftAvailabilityEventType; mode: ShiftPlanningMode }>()

const { isDisabled, isSelected, draggingAvailability, shiftPlanningForm } = useShiftPlanning()

const { toggleAvailability } = useShiftAvailabilityEvent()

function handleClick() {
    if (mode === 'view') {
        toggleAvailability(availability)

        return
    }

    if (mode === 'individual') {
        if (isSelected(availability)) {
            shiftPlanningForm.shifts = shiftPlanningForm.shifts.filter((shift) => !shift.availabilities.some((item) => item.id === availability.id))
        } else {
            shiftPlanningForm.shifts.push({
                availabilities: [availability],
                location_type_id: availability.location_type_id,
                note: '',
            })
        }
    }
}

const handleDragStart = (event: DragEvent) => {
    isDragging.value = true

    if (event.dataTransfer) {
        event.dataTransfer.setData('text/plain', 'availability')
    }

    // Create a custom drag image
    if (event.target instanceof HTMLElement) {
        const dragImage = event.target.cloneNode(true) as HTMLElement
        dragImage.style.transform = 'scale(0.95)'
        dragImage.style.opacity = '0.8'
        dragImage.style.position = 'absolute'
        dragImage.style.top = '-1000px'
        document.body.appendChild(dragImage)
        event.dataTransfer?.setDragImage(dragImage, 0, 0)
        setTimeout(() => document.body.removeChild(dragImage), 0)
    }

    draggingAvailability.value = availability

    return true
}

const isDragging = ref(false)

const handleDragEnd = () => {
    isDragging.value = false

    draggingAvailability.value = undefined
}

const disabled = computed(() => mode !== 'view' && isDisabled(availability))
const selected = computed(() => isSelected(availability))

const draggable = computed(() => mode === 'team' && !disabled.value && !selected.value)
</script>

<template>
    <ShiftAvailabilityEvent
        :availability
        :draggable
        @dragstart="handleDragStart"
        @dragend="handleDragEnd"
        @click="handleClick"
        :selected
        :class="{
            grayscale: disabled,
            'cursor-grab active:cursor-grabbing': draggable,
            'scale-90 opacity-50': isDragging,
        }"
    />
</template>
