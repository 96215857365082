<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import type { ShiftMember } from '@app/types/shifts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])

const { model } = defineProps<{ model: ShiftMember }>()

const form = useForm({
    note: '',
})

function submit() {
    form.post(route('shifts.member.decline', model.id))
}
</script>

<template>
    <Form :form @submit="submit">
        <FormSection>
            <FormField prop="note" class="col-span-12">
                <FormTextArea v-model="form.note" :placeholder="$t('note.placeholder')" />
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('content:closed')"></CancelButton>
            <SaveButton :disabled="form.processing">{{ $t('buttons.decline') }}</SaveButton>
        </FormActions>
    </Form>
</template>
