<script setup lang="ts">
import ActivityFeed from '@app/components/activityFeeds/ActivityFeed.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import { useColor } from '@app/composables/useColor'
import { useLocale } from '@app/composables/useLocale'
import type { Order } from '@app/types/orders'
import { Bars4Icon, ShoppingBagIcon, TagIcon, UserIcon } from '@heroicons/vue/24/outline'
import OrderCustomerDetails from './OrderCustomerDetails.vue'
import OrderDetails from './OrderDetails.vue'
import OrderLineDetails from './OrderLineDetails.vue'

defineProps<{ order: Order }>()

const { dbTranslate } = useLocale()
const { getOrderStatusColor } = useColor()
const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="order" :dismissable="true" @close="emit('close')">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle> Order ID: {{ order.id }}</DetailCardTitle>
            <Badge size="lg" class="leading-6" :label="dbTranslate(order.status.name)" :color="getOrderStatusColor(order.status.slug)" />
            <div class="flex flex-col items-start gap-2">
                <DetailCardLabel :label="$t('attributes.name') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ order.customer ? order.customer.full_name : $t('attributes.unknown') }} </span>
                </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.client_project') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ order.client_project.name }} </span>
                </DetailCardLabel>
                <DetailCardLabel v-if="order.total_price.formatted != order.total_price_per_month.formatted" :label="$t('attributes.total_price') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ order.total_price.formatted }} </span>
                </DetailCardLabel>
                <DetailCardLabel v-if="order.total_price.amount > 0" :label="$t('attributes.price_per_month') + ' :'">
                    <span class="text-sm leading-6 font-semibold uppercase"> {{ order.total_price_per_month.formatted }} </span>
                </DetailCardLabel>
            </div>
        </div>
        <template #tabs>
            <DetailCardTab tab="order" :label="$t('attributes.order')">
                <template #icon>
                    <ShoppingBagIcon />
                </template>
                <OrderDetails :order="order" />
            </DetailCardTab>
            <DetailCardTab tab="lines" :label="$t('attributes.order_lines')">
                <template #icon>
                    <TagIcon />
                </template>
                <OrderLineDetails :order_lines="order.order_lines" />
            </DetailCardTab>
            <DetailCardTab v-if="order.customer" tab="customer" :label="$t('attributes.customer')">
                <template #icon>
                    <UserIcon />
                </template>
                <OrderCustomerDetails :customer="order.customer" />
            </DetailCardTab>
            <DetailCardTab tab="activity" :label="$t('activities.label')">
                <template #icon>
                    <Bars4Icon />
                </template>
                <ActivityFeed :activities="order.combined_activity_feeds" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
