<script setup lang="ts">
import TwoPaneLayout from '@app/components/layout/TwoPaneLayout.vue'
import Form from '@app/components/settings/finance/hourlyWages/Form.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import Settings from '@app/layouts/Settings.vue'
import { type HourlyWage } from '@app/types/finance'
import type { ResourceCollection, WorkType } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { type PropType, toRefs } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    hourlyWages: {
        type: Object as PropType<ResourceCollection<HourlyWage>>,
        required: true,
    },
    selectedHourlyWage: {
        type: [Object, null] as PropType<HourlyWage | null>,
        required: true,
    },
    workTypes: {
        type: Object as PropType<Array<WorkType>>,
        required: true,
    },
})

const { selectedHourlyWage } = toRefs(props)
const { toggleCard } = useDetailCard(selectedHourlyWage, 'selectedHourlyWage')

const columns = [
    {
        type: 'string',
        header: 'attributes.work_type',
        properties: { name: 'name', relation: 'work_type', translate: true },
    },
    {
        type: 'string',
        header: 'attributes.work_type_rank',
        properties: { name: 'name', relation: 'work_type_rank', translate: true },
        sort: 'work_type_rank',
    },
    {
        type: 'string',
        header: 'attributes.age',
        properties: { name: 'age' },
        sort: 'age',
    },
    {
        type: 'amount',
        header: 'attributes.amount',
        properties: { name: 'amount' },
        sort: 'amount',
    },
    {
        type: 'date',
        header: 'attributes.amount_start_date',
        properties: { name: 'amount_start_date' },
        sort: 'amount_start_date',
    },
]

function closeForm() {
    applyFilter({
        selected: null,
    })
}
</script>

<template>
    <Settings group="finance">
        <TwoPaneLayout>
            <DataTable
                class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
                :items="hourlyWages"
                :columns="columns"
                only="hourlyWages"
                :clickable="true"
                :activeId="selectedHourlyWage?.id"
                @row:clicked="(item) => toggleCard(item.id)"
            >
                <template #filter>
                    <FilterSection>
                        <DropdownMultiFilter :items="workTypes" :label="$t('attributes.work_type')" slug="work_type" by="slug" />
                        <ResetFilterButton class="mt-2" :href="route('settings.finance.hourly-wage.index')" :label="$t('buttons.clear')" />
                    </FilterSection>
                </template>
            </DataTable>
            <template #detail v-if="selectedHourlyWage">
                <Card @close="closeForm" :dismissable="true">
                    <Form :hourlyWage="selectedHourlyWage" :key="selectedHourlyWage?.id" @submit="closeForm" />
                </Card>
            </template>
        </TwoPaneLayout>
    </Settings>
</template>
