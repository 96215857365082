import axios from 'axios'
import { ref } from 'vue'
import { route } from 'ziggy-js'

type LocationResult = {
    city: string
    street: string
}

export function useLocationSearch() {
    const street = ref<string>()
    const placeOfResidence = ref<string>()
    const notFound = ref(false)

    async function search(postalCode: string, houseNumber: string | number) {
        const { data: locations } = await axios.get<LocationResult[]>(route('api.v1.location.search', { postal_code: postalCode, house_number: houseNumber }))

        if (locations.length > 0) {
            street.value = locations[0].street
            placeOfResidence.value = locations[0].city
        } else {
            notFound.value = true
        }
    }

    function reset() {
        street.value = undefined
        placeOfResidence.value = undefined
        notFound.value = false
    }

    return {
        street,
        placeOfResidence,
        notFound,
        search,
        reset,
    }
}
