<script setup>
import { useHumanReadableDate } from '@app/composables/date/useHumanReadableDate'
import { computed } from 'vue'

const props = defineProps({
    conversation: Object,
    selected: Boolean,
})

const { getHumanReadableDate } = useHumanReadableDate()

const emit = defineEmits(['select'])

const lastMessage = computed(() => props.conversation.last_message)
const hasUnreadMessages = computed(() => props.conversation.unread_messages_count > 0)
</script>

<template>
    <li
        class="relative cursor-pointer px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-white"
        :class="[selected ? 'bg-white' : 'bg-gray-50']"
    >
        <div @click="emit('select')" class="hover:pointer-cursor focus:outline-hidden block">
            <span class="absolute inset-0" aria-hidden="true" />
            <div class="flex w-full items-center justify-between">
                <p class="truncate text-sm font-medium text-gray-900">
                    {{ conversation.communicable?.full_name }}
                </p>
                <time v-if="lastMessage" :datetime="lastMessage.created_at" class="shrink-0 whitespace-nowrap text-xs text-gray-400"
                    >{{ getHumanReadableDate(lastMessage.created_at) }}
                </time>
            </div>
            <div class="mt-1 flex justify-between space-x-2">
                <p class="truncate text-sm text-gray-500" :class="{ 'w-5/6': hasUnreadMessages }">
                    {{ lastMessage?.message }}
                </p>
                <span
                    v-if="hasUnreadMessages"
                    class="bg-primary-600 hover:bg-primary-900 inline-block h-[18px] w-[18px] rounded-full text-center text-[10px] leading-[18px] text-white"
                >
                    {{ conversation.unread_messages_count }}
                    <span class="sr-only">Notification of a new message</span>
                </span>
            </div>
        </div>

        <div v-if="lastMessage" class="mt-1">
            <p class="line-clamp-2 text-sm text-gray-600">
                {{ lastMessage.preview }}
            </p>
        </div>
    </li>
</template>
