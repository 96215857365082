<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Select from '@app/components/ui/form/select/Select.vue'
import SelectOption from '@app/components/ui/form/select/SelectOption.vue'
import { useCreateShiftAvailabilityForm } from '@app/composables/shifts/availabilities/useCreateShiftAvailabilityForm'
import { useLocale } from '@app/composables/useLocale'
import { type User } from '@app/types/shared'
import type { ShiftTemplate } from '@app/types/shifts'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { ArrowsPointingInIcon, CalendarIcon, ClockIcon, MapIcon, PaperClipIcon } from '@heroicons/vue/20/solid'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, ref, watch } from 'vue'

const { date, plannerShiftTemplates, user } = defineProps<{
    date: Date
    plannerShiftTemplates: ShiftTemplate[]
    user: User
}>()

const emit = defineEmits(['close'])
const { getLocale } = useLocale()
const selectedDate = ref(date)

const { form, clientProjects, selectableLocationTypes, selectedWorkTypeSlug, selectableBranches, selectableShiftHours, submit } =
    useCreateShiftAvailabilityForm(plannerShiftTemplates, user)

const key = computed(() => selectedDate.value.toDateString())

const selectedShiftHourId = ref()

watch(selectedShiftHourId, () => {
    form.shift_availabilities = {
        [key.value]: {
            date: key.value,
            shift_hour_id: selectedShiftHourId.value,
        },
    }
})
</script>

<template>
    <Card dismissable @close="emit('close')" id="detail-card">
        <div class="flex w-full flex-col items-start gap-4">
            <div class="flex gap-2">
                <div class="w-full text-center text-lg leading-6 font-medium text-gray-900">{{ $t('shifts.create_availability.title') }}</div>
            </div>
            <div class="flex w-full flex-col gap-4">
                <div class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <PaperClipIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <Select
                            class="w-full"
                            v-model="form.client_project_id"
                            :placeholder="translatedSelectPlaceholder('attributes.client_project')"
                            :disabled="clientProjects.length === 0"
                            autoselect
                        >
                            <SelectOption
                                v-for="clientProject in clientProjects"
                                :value="clientProject.id"
                                :label="clientProject.name"
                                :key="clientProject.id"
                            />
                        </Select>
                    </div>
                </div>
                <div
                    v-if="selectedWorkTypeSlug !== 'promotions' && selectableLocationTypes.length > 0"
                    v-show="selectableLocationTypes.length > 1"
                    class="flex flex-col gap-2"
                >
                    <div class="flex items-center space-x-4">
                        <ArrowsPointingInIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />

                        <Select
                            class="w-full"
                            v-model="form.location_type_id"
                            :placeholder="translatedSelectPlaceholder('attributes.location_type')"
                            autoselect
                        >
                            <SelectOption
                                v-for="locationType in selectableLocationTypes"
                                :value="locationType.id"
                                :label="locationType.name"
                                :key="locationType.id"
                            />
                        </Select>
                    </div>
                </div>
                <FieldError :message="form.errors.location_type_id" />
                <div v-if="selectableBranches.length > 0" class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <MapIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <Select class="w-full" v-model="form.branch_id" :placeholder="translatedSelectPlaceholder('attributes.branch')" autoselect>
                            <SelectOption v-for="branch in selectableBranches" :value="branch.id" :label="branch.name" :key="branch.id" />
                        </Select>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <div class="flex items-center space-x-4">
                        <CalendarIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <VueDatePicker
                            class="w-2/3"
                            id="availability_date"
                            v-model="selectedDate"
                            :locale="getLocale().code"
                            :auto-position="false"
                            auto-apply
                            :enable-time-picker="false"
                            text-input
                            format="dd-MM-yyyy"
                            placeholder="Select a date"
                        />
                    </div>
                    <FieldError
                        v-if="form.errors.shift_availabilities && form.errors.shift_availabilities[key]"
                        :message="form.errors.shift_availabilities[key]"
                        class="mt-2"
                    />
                </div>

                <div v-if="selectableShiftHours.length > 0" class="flex flex-col gap-4">
                    <div class="flex items-center space-x-4">
                        <ClockIcon class="h-5 w-5 text-zinc-500" aria-hidden="true" />
                        <Select class="w-full" v-model="selectedShiftHourId" :placeholder="translatedSelectPlaceholder('attributes.shift_hour')" autoselect>
                            <SelectOption
                                v-for="shiftHours in selectableShiftHours"
                                :value="shiftHours.id"
                                :label="`${shiftHours.start_time} - ${shiftHours.end_time}`"
                                :key="shiftHours.id"
                            />
                        </Select>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-row-reverse justify-between">
            <SaveButton @click="submit(() => $emit('close'))">Create</SaveButton>
        </div>
    </Card>
</template>
