<script setup lang="ts">
import FormLabel from '@app/components/orders/orderForms/wnl/FormLabel.vue'
import PrimaryButton from '@app/components/orders/orderForms/wnl/PrimaryButton.vue'
import SecondaryButton from '@app/components/orders/orderForms/wnl/SecondaryButton.vue'
import TextField from '@app/components/orders/orderForms/wnl/TextField.vue'
import WnlOrderForm from '@app/components/orders/orderForms/wnl/WnlOrderForm.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import Wnl from '@app/layouts/Wnl.vue'
import type { Bank } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'

defineOptions({
    layout: Wnl,
})

type State = {
    bank_id: number | null
    iban: string
}

const { state, steps } = defineProps<{ steps: FormStep[]; banks: Bank[]; state: Partial<State> }>()

const { navigateToPreviousStep } = useFormWizard(steps)

const form = useForm({
    bank_id: state.bank_id ?? null,
    iban: state.iban ?? '',
})
</script>

<template>
    <WnlOrderForm :steps title="Betaling" description="Nog een paar gegevens en je bent lid van WNL!">
        <Form class="z-10 flex grow flex-col gap-2" :form @submit="form.post('')">
            <div>
                <FormLabel for="bank">Kies uw bank</FormLabel>

                <select id="bank" v-model="form.bank_id" class="focus:ring-wnl-purple block w-full rounded-sm border-none bg-[#f6f6f6] px-4 py-3">
                    <option :value="null">Kies uw bank</option>
                    <option v-for="bank in banks" :value="bank.id">{{ bank.name }}</option>
                </select>

                <FieldError :message="form.errors.bank_id" />
            </div>

            <div>
                <FormLabel for="iban">IBAN</FormLabel>
                <TextField v-model="form.iban" name="iban" placeholder="NL00XXXX000000000" :error="form.errors.iban" autocomplete="off" />
                <FieldError class="mt-2" :message="form.errors.iban" />
            </div>

            <div class="mt-auto flex items-center justify-between">
                <PrimaryButton class="min-w-52" :disabled="form.processing || !form.bank_id || !form.iban">Verder</PrimaryButton>
                <SecondaryButton type="button" @click="navigateToPreviousStep">Stap terug</SecondaryButton>
            </div>
        </Form>
    </WnlOrderForm>
</template>
