<script setup>
import { useLocale } from '@app/composables/useLocale'
import { applyFilter } from '@app/utils/filter'
import { ucfirst } from '@app/utils/transform'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { trans } from 'laravel-vue-i18n'
import { inject, ref, watch } from 'vue'
import FilterLabel from './FilterLabel.vue'

const props = defineProps({
    items: {
        type: Object,
        required: true,
    },
    propertyName: {
        type: String,
        required: true,
    },
    border: {
        type: Boolean,
        default: true,
    },
    label: String,
    translateDb: {
        type: Boolean,
        default: true,
    },
    by: String,
    slug: {
        type: String,
        required: true,
    },
})

const params = route().params

const { dbTranslate } = useLocale()

const only = inject('datatable:only', [])

const selectedItem = ref(props.items.find((item) => item[props.by] == params[props.slug]) || null)

function getTranslatedOrNormal(string) {
    return props.translateDb ? dbTranslate(string) : trans(string)
}

watch(selectedItem, (newValue) => {
    applyFilter({ [props.slug]: newValue[props.by] }, { only: [only] })
})
</script>

<template>
    <div v-if="items.length > 0" class="w-full min-w-[100px] md:w-auto">
        <FilterLabel v-if="label">{{ ucfirst(label) }}</FilterLabel>
        <Listbox as="div" v-model="selectedItem" :by="by">
            <div class="relative">
                <ListboxButton
                    :class="[
                        border ? 'border border-zinc-300 shadow-none' : 'shadow-xs',
                        'focus:border-primary-500 focus:ring-primary-500 relative w-full cursor-pointer rounded-md py-2 pr-10 pl-3 text-left text-sm hover:bg-zinc-50 focus:ring-2 focus:outline-hidden',
                    ]"
                >
                    <span class="ml-1 block truncate">{{ selectedItem ? getTranslatedOrNormal(selectedItem[propertyName]) : $t('filters.checkbox.all') }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronDownIcon class="text-primary-500 size-6" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 absolute z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-hidden sm:text-sm"
                    >
                        <ListboxOption as="template" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-primary-500 text-white' : 'text-gray-900', 'relative cursor-default py-2 pr-9 pl-3 select-none']">
                                <div class="flex items-center pr-3">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ $t('filters.checkbox.all') }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                        <ListboxOption as="template" v-for="item in items" :value="item" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-primary-600 text-white' : 'text-gray-900', 'relative cursor-default py-2 pr-9 pl-3 select-none']">
                                <div class="flex items-center pr-3">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{
                                        getTranslatedOrNormal(item[propertyName])
                                    }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>
