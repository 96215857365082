<script setup lang="ts">
import FileDownload from '@app/components/ui/button/FileDownload.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import type { Contract } from '@app/types/contracts'
import { isImage } from '@app/utils/file'
import { computed } from 'vue'

const props = defineProps<{ contract: Contract }>()

const personalInformation = computed(() => props.contract.user || props.contract.contract_personal_information)

const bankCardPhoto = computed(() => personalInformation.value?.files.find((file) => file.type === 'bank_card_photo')?.path)
</script>

<template>
    <div v-if="personalInformation" class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.account_holder_name')">
            {{ personalInformation.account_holder_name }}
        </DetailItem>
        <DetailItem :label="$t('attributes.iban')">
            {{ personalInformation.iban }}
        </DetailItem>
        <DetailItem :label="$t('attributes.bsn')">
            {{ personalInformation.bsn }}
        </DetailItem>
        <DetailItem :label="$t('attributes.payroll_tax_credit')">
            {{ $t(contract.payroll_tax_credit ? 'form.labels.yes' : 'form.labels.no') }}
        </DetailItem>

        <DetailItem v-if="bankCardPhoto" class="col-span-2" :label="$t('attributes.bank_card_photo')">
            <img
                v-if="contract.status.slug === 'signed' && isImage(bankCardPhoto)"
                :src="bankCardPhoto"
                :alt="$t('attributes.bank_card_photo')"
                class="mt-1 max-w-sm"
            />
            <FileDownload v-else :name="$t('attributes.bank_card_photo')" :path="bankCardPhoto" class="mt-1" />
        </DetailItem>
    </div>
</template>
