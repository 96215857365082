<script setup lang="ts">
import type { ShiftMember, ShiftMemberIssue } from '@app/types/shifts'
import { PauseCircleIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'
import ShiftMemberIssueTemplate from './ShiftMemberIssueTemplate.vue'

const { shiftMember } = defineProps<{ issue: ShiftMemberIssue; shiftMember: ShiftMember }>()
const maxAllowedBreakDuration = computed(() => shiftMember.shift_break_duration_minutes + shiftMember.shift_break_max_extra_minutes)
</script>

<template>
    <ShiftMemberIssueTemplate :issue>
        <template #icon>
            <PauseCircleIcon class="h-5 w-5" :class="issue.resolved_at ? 'text-green-500' : 'text-zinc-500'" />
        </template>
        <p>
            {{ $t('shifts.quality_control.break_allowed') }}:
            {{ maxAllowedBreakDuration }}
            {{ $t('shifts.quality_control.minutes') }}
        </p>
        <p>
            {{ $t('shifts.quality_control.break_actual') }}:
            {{ shiftMember.break_duration_minutes }}
            {{ $t('shifts.quality_control.minutes') }}
        </p>
    </ShiftMemberIssueTemplate>
</template>
