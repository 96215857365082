<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Authenticatable } from '@app/types/shared'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'

defineEmits(['click'])

const { user } = defineProps<{ user: Authenticatable }>()

const { can, isSuperAdmin } = useUserCan()

const show = computed(() => can('user.update-roles') && !isSuperAdmin(user) && !user.deleted_at)
</script>

<template>
    <RoundButton v-if="show" :label="$t('form.title.edit_role')" @click="$emit('click')">
        <PencilIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
