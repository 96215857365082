<script setup lang="ts">
import ShiftsSettingsTabs from '@app/components/settings/shifts/ShiftsSettingsTabs.vue'
import Form from '@app/components/settings/shifts/templates/Form.vue'
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import type { Branch, ClientProject, LocationType, ResourceCollection } from '@app/types/shared'
import type { ShiftTemplate } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const {
    selectedTemplate,
    create: formShown,
    templates,
} = defineProps<{
    templates: ResourceCollection<ShiftTemplate>
    selectedTemplate: ShiftTemplate | null
    clientProjects: ClientProject[]
    branches: Branch[]
    locationTypes: LocationType[]
    create: boolean
}>()

const { toggleCard } = useDetailCard(
    computed(() => selectedTemplate),
    'selectedTemplate'
)

const columns = computed(() => [
    {
        name: 'client_project',
        header: 'attributes.client_project',
    },
    {
        name: 'branch',
        header: 'attributes.branch',
    },
    {
        name: 'location_type',
        header: 'attributes.location_type',
    },
])

function showCreateForm() {
    applyFilter({
        selected: null,
        create: true,
    })
}

function closeForm() {
    applyFilter({
        selected: null,
        create: null,
    })
}
</script>

<template>
    <ShiftsSettingsTabs>
        <Button @click="showCreateForm">{{ $t('actions.create') }}</Button>
    </ShiftsSettingsTabs>

    <div class="scrollbar-hide flex items-start gap-4">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[selectedTemplate || create ? 'hidden lg:inline-block' : 'inline-block']"
            :items="templates"
            :columns="columns"
            only="templates"
            :clickable="true"
            :active-id="selectedTemplate?.id"
            @row:clicked="(item) => toggleCard(item.id, ['selected'])"
        >
            <template #filter>
                <FilterSection>
                    <DropdownMultiFilter
                        :items="clientProjects"
                        propertyName="name"
                        :label="$t('attributes.client_project')"
                        only="templates"
                        slug="client_project"
                        by="slug"
                    />

                    <DropdownMultiFilter :items="branches" propertyName="name" :label="$t('attributes.branch')" only="templates" slug="branch" by="slug" />

                    <DropdownMultiFilter
                        :items="locationTypes"
                        propertyName="name"
                        :label="$t('attributes.location_type')"
                        only="templates"
                        slug="location_type"
                        by="slug"
                    />

                    <ResetFilterButton :href="route('settings.shifts.template.index')" />
                </FilterSection>
            </template>

            <template #column.client_project="{ item }">
                {{ item.client_project?.name }}
            </template>

            <template #column.branch="{ item }">
                {{ item.branch.name }}
            </template>

            <template #column.location_type="{ item }">
                {{ item.location_type.name }}
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <Card v-if="formShown || selectedTemplate" class="md:min-w-[29rem] lg:w-2/3" @close="closeForm" :dismissable="true">
                <Form :template="selectedTemplate" :key="selectedTemplate?.id" @submit="closeForm" />
            </Card>
        </Transition>
    </div>
</template>
