<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { Shift } from '@app/types/shifts'
import { getZonedDateTime } from '@app/utils/date'

defineProps<{ shift: Shift }>()

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full justify-start gap-4 text-left">
        <div class="flex flex-col justify-start space-y-2 md:w-1/2">
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.work_type') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ dbTranslate(shift.clientProject.work_type.name) }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.client_project') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.clientProject.name }}</span>
            </div>

            <div v-if="shift.branch" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.branch_id') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.branch.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.location_type') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.locationType.name }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.application') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.application.name }}</span>
            </div>
        </div>
        <div class="flex flex-col justify-start space-y-2 md:w-1/2">
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.start') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ getZonedDateTime(shift.start) }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.end') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ getZonedDateTime(shift.end) }}</span>
            </div>
            <div v-if="shift.locations.length" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.location') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.locations }}</span>
            </div>
            <div v-if="shift.external_id" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('attributes.external_id') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.external_id }}</span>
            </div>
            <div v-if="shift.hours" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('shifts.obliged_break_duration') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.hours.break_duration_minutes }}</span>
            </div>
            <div v-if="shift.hours" class="flex flex-col">
                <span class="text-xs leading-4 font-normal text-zinc-400 uppercase">{{ $t('shifts.optional_break_duration') }}</span>
                <span class="text-sm leading-5 font-normal text-zinc-700">{{ shift.hours.break_max_extra_minutes }}</span>
            </div>
        </div>
    </div>
</template>
