<script setup lang="ts">
import FormLabel from '@app/components/orders/orderForms/wnl/FormLabel.vue'
import PrimaryButton from '@app/components/orders/orderForms/wnl/PrimaryButton.vue'
import Radio from '@app/components/orders/orderForms/wnl/Radio.vue'
import SecondaryButton from '@app/components/orders/orderForms/wnl/SecondaryButton.vue'
import TextField from '@app/components/orders/orderForms/wnl/TextField.vue'
import WnlOrderForm from '@app/components/orders/orderForms/wnl/WnlOrderForm.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import { useLocationSearch } from '@app/composables/useLocationSearch'
import Wnl from '@app/layouts/Wnl.vue'
import { useForm } from '@inertiajs/vue3'
import { reset } from 'laravel-vue-i18n'
import debounce from 'lodash/debounce'
import { watch, watchEffect } from 'vue'

defineOptions({
    layout: Wnl,
})

type State = {
    phone_number: string
    date_of_birth: string
    gender: string | null
    postal_code: string
    street: string
    house_number: string
    house_number_suffix: string
    place_of_residence: string
}

const { state, steps } = defineProps<{ steps: FormStep[]; state: Partial<State> }>()

const { navigateToPreviousStep } = useFormWizard(steps)

const form = useForm({
    phone_number: state.phone_number ?? '',
    date_of_birth: state.date_of_birth ?? '',
    gender: state.gender ?? null,
    postal_code: state.postal_code ?? '',
    street: state.street ?? '',
    house_number: state.house_number ?? '',
    house_number_suffix: state.house_number_suffix ?? '',
    place_of_residence: state.place_of_residence ?? '',
})

const { search, street, placeOfResidence } = useLocationSearch()

const debouncedSearch = debounce(search, 300)

watch([street, placeOfResidence], () => {
    form.street = street.value || ''
    form.place_of_residence = placeOfResidence.value || ''
})

watchEffect(() => {
    if (form.postal_code.length === 6 && !!form.house_number) {
        debouncedSearch(form.postal_code, form.house_number)
    } else {
        reset()
    }
})
</script>

<template>
    <WnlOrderForm :steps title="Aanvullende gegevens" description="Nog een paar gegevens en je bent lid van WNL!">
        <Form class="z-10 flex grow flex-col gap-2" :form @submit="form.post('')">
            <div>
                <FormLabel for="phone_number">Wat is uw telefoonnummer?</FormLabel>
                <TextField
                    v-model="form.phone_number"
                    name="phone_number"
                    placeholder="Typ hier uw telefoonnummer"
                    :error="form.errors.phone_number"
                    autocomplete="off"
                />
                <FieldError class="mt-2" :message="form.errors.phone_number" />
            </div>

            <div>
                <FormLabel for="date_of_birth">Wat is uw geboortedatum?</FormLabel>
                <TextField v-model="form.date_of_birth" name="date_of_birth" placeholder="DD-MM-YYYY" :error="form.errors.date_of_birth" autocomplete="off" />
                <FieldError class="mt-2" :message="form.errors.date_of_birth" />
            </div>

            <div>
                <FormLabel>Wat is uw geslacht?</FormLabel>

                <fieldset class="flex flex-col gap-2">
                    <Radio name="gender" v-model="form.gender" value="female">Vrouw</Radio>
                    <Radio name="gender" v-model="form.gender" value="male">Man</Radio>
                    <Radio name="gender" v-model="form.gender" value="unknown">Anders</Radio>
                </fieldset>

                <FieldError class="mt-2" :message="form.errors.gender" />
            </div>

            <div class="grid grid-cols-6 gap-x-4">
                <FormLabel for="postal_code" class="col-span-6">Wat is uw adres? (postcode + huisnummer)</FormLabel>
                <TextField
                    class="col-span-2"
                    v-model="form.postal_code"
                    name="postal_code"
                    placeholder="1234AB"
                    :error="form.errors.postal_code"
                    autocomplete="off"
                />
                <TextField
                    class="col-span-2"
                    v-model="form.house_number"
                    name="house_number"
                    placeholder="3"
                    :error="form.errors.house_number"
                    autocomplete="off"
                />
                <TextField
                    class="col-span-2"
                    v-model="form.house_number_suffix"
                    name="house_number_suffix"
                    placeholder="toe."
                    :error="form.errors.house_number_suffix"
                    autocomplete="off"
                />
            </div>

            <div v-if="form.street && form.place_of_residence">
                Uw adres is {{ form.street }} {{ form.house_number }} in {{ form.place_of_residence }}. Als dit niet klopt, controleer dan uw postcode.
            </div>

            <div class="mt-auto flex items-center justify-between">
                <PrimaryButton class="min-w-52" :disabled="form.processing || !form.place_of_residence || !form.street">Verder</PrimaryButton>
                <SecondaryButton type="button" @click="navigateToPreviousStep">Stap terug</SecondaryButton>
            </div>
        </Form>
    </WnlOrderForm>
</template>
