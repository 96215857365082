<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import type { User, WorkType } from '@app/types/shared'
import { AdjustmentsHorizontalIcon } from '@heroicons/vue/24/solid'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import ShiftSettingsForm from '../ShiftSettingsForm.vue'

const { user } = defineProps<{ user: User }>()

const { toggle } = useActiveButton()

const shiftSettingsWorkTypes = computed(() => usePage().props.shiftSettingsWorkTypes as WorkType[])
const show = computed(() => !user.deleted_at && shiftSettingsWorkTypes.value.length > 0)
</script>

<template>
    <RoundButton v-if="show" :label="$t('user.actions.shift_settings')" @click="toggle(ShiftSettingsForm)">
        <AdjustmentsHorizontalIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
