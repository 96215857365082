<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { ShiftMember, ShiftMemberIssue } from '@app/types/shifts'
import { ucfirst } from '@app/utils/transform'
import { ClockIcon } from '@heroicons/vue/20/solid'
import { formatDistance } from 'date-fns'
import { computed } from 'vue'
import ShiftMemberIssueTemplate from './ShiftMemberIssueTemplate.vue'

const { shiftMember } = defineProps<{ issue: ShiftMemberIssue; shiftMember: ShiftMember }>()

const { getLocale } = useLocale()

const timeLate = computed(() =>
    formatDistance(shiftMember.shift_end, shiftMember.end, {
        locale: getLocale(),
    })
)
</script>

<template>
    <ShiftMemberIssueTemplate :issue>
        <template #icon>
            <ClockIcon class="h-5 w-5" :class="issue.resolved_at ? 'text-green-500' : 'text-zinc-500'" />
        </template>
        {{ ucfirst(timeLate) }}
    </ShiftMemberIssueTemplate>
</template>
